import { getEnv, isShowSelectEnv } from '@/utils/changeEnv';
import type { APPs } from '@@/plugin-generate-access/accessUtil';
import { Space } from 'antd';
import { useModel, SelectLang } from 'umi';
import FormattedMessage from '@/components/FormattedMessage/FormattedMessage';
import Avatar from './AvatarDropdown';
import styles from './index.less';
import { defaultLangUConfigMap } from '@/utils/locales';
export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight = ({ app, lang }: { app?: APPs; lang: string[] }) => {
  const { initialState } = useModel('@@initialState');
  if (!initialState || !initialState.settings) {
    return null;
  }
  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }

  const selectedLangs = lang?.map((langCode) => defaultLangUConfigMap[langCode]);
  const postData = selectedLangs.map((langData) => ({
    lang: langData.lang,
    label: langData.label,
    icon: langData.icon,
    title: langData.title,
  }));

  return (
    <Space className={className}>
      <em style={{ color: 'rgba(0,0,0,0.8)' }}>
        {'tip:  '}
        <FormattedMessage id="pages.rightContent.timeTip" />
      </em>
      {isShowSelectEnv() && (
        <b style={{ color: 'rgba(0,161,255)' }}>
          <FormattedMessage id="pages.rightContent.evn" /> {getEnv()}
        </b>
      )}
      <Avatar />

      {app === 'ap' ? (
        <SelectLang className={styles.action} postLocalesData={() => postData} />
      ) : (
        <SelectLang className={styles.action} />
      )}
    </Space>
  );
};
export default GlobalHeaderRight;
