/**
 * Created by cleverdou on 2022/4/29.
 */

import { HOST_DEV, HOST_PROD, HOST_SIT, HOST_TEST, HOST_ID_DEV } from '../../config/proxy';

(window as any).apiPrefix = getEnvPrefix();

export const ENV = ['dev', 'id-dev', 'test', 'sit', 'prod'];

export function changeEnv(env: string) {
  if (!isTest) {
    return;
  }
  localStorage.setItem('env', env);
  (window as any).apiPrefix = getEnvPrefix();
}

function getEnvPrefix() {
  let env: string;
  if (!isTest) {
    const result = /-(\w+)\./.exec(location.hostname);
    if (result) {
      env = result[1];
    } else {
      env = '';
    }
  } else {
    env = getEnv();
  }

  switch (env) {
    case 'prod':
      return HOST_PROD;
    case 'sit':
      return HOST_SIT;
    case 'test':
      return HOST_TEST;
    case 'id-dev':
      return HOST_ID_DEV;
    case 'dev':
      return HOST_DEV;
    default:
      return _apiPrefix;
  }
}

export function isProd(env: string) {
  return env === 'prod';
}

export function getEnv() {
  return localStorage.getItem('env') || 'test';
}
export function isShowSelectEnv() {
  return isTest;
}
