export default {
  id: 'page.collection.ApproveCenter',
  components: [
    'nullify',
    'changeApprover',
    'download',
    'undo',
    'tab_handling',
    'tab_handled',
    'tab_mine',
    'tab_all',
  ],
} as const;
