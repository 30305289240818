export default {
  'menu.creditReview': 'Credit Review',
  'menu.creditReview.FirstReview': 'First Review',
  'menu.creditReview.FirstReview.auditReview': 'Pending Review List',
  'menu.creditReview.FirstReview.auditedReview': 'Reviewed List',
  'menu.creditReview.FirstReview.manualAuditStatistics': 'Manual Review Statistics',
  'menu.creditReview.creditRecordManager': 'Credit Record Management',
  'menu.creditReview.withdrawManager': 'Withdrawal Record Management',
  'menu.userManage': 'user managerment',
  'menu.userManage.userFeedback': 'user Feedback',
  'menu.userManage.userList': 'user list',
  'menu.creditReview.creditRecordDetail': 'Review Detail',
  'menu.userManage.userListDetail': 'Credit Record Detail',
  'menu.loanManage': 'Disbursement Management',
  'menu.loanManage.loanRecords': 'Disbursement Record',
  'menu.collection.mobiles': 'My Collection Mobiles',
  'menu.collection.contactMobiles': 'Emergency Contact No.',
  'menu.collection.blacklist': 'blacklist',
  'menu.collection.specialDivision': 'Special Distribution',
  'menu.collection.divisionRecord': 'Distribution Record',
};
