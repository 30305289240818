/**
 * Created by zhaocaimin on 2022/3/10.
 * OwnInfoTab ：个人信息tab
 
 * WorkInfoTab ： 工作信息tab
 * - CompanyPhone ： 公司电话
 * 
 * ContactInfoTab : 联系信息tab
 * - ContactInfoPhone ： 联系信息电话
 * BankCardInfoTab : 银行卡tab
 * HistoryAccountInfoTab : 历史账户信息tab
 * - HistoryAccountPhone ：历史账户信息的phone
 * ComplaintInfoTab ： 投诉信息Info
 * MultHeaderInfoTab : 多头信息tab
 * ContactEvoke : 唤起联系人外呼按钮
 * - BasicPhone ：  basicinfo 手机号
 * - BasicBackupPhone : basicinfo 备用电话
 * -  
 * WhatsApp 唤起whatsapp的按钮
 
 */

export default {
  id: 'page.collection.mydetail',
  components: [
    'customerInfo',
    'OwnInfoTab',
    'WorkInfoTab',
    'ContactInfoTab',
    'BankCardInfoTab',
    'ComplaintInfoTab',
    'HistoryAccountInfoTab',
    'MultHeaderInfoTab',
    'BasicPhone',
    'BasicBackupPhone',
    'CompanyPhone',
    'HistoryAccountPhone',
    'ContactInfoPhone',
    'ContactEvoke',
    'WhatsApp',
  ],
} as const;
