import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import ProForm, { ProFormText } from '@ant-design/pro-form';
// @ts-ignore
import { useIntl, Link, history, FormattedMessage, SelectLang, useModel } from 'umi';
import Footer from '@/components/Footer';
import { login } from '@/services/user/api';

import styles from './index.less';
import { stringify } from 'querystring';
import { logOut, saveAuthToken } from '@/utils/storage';
import { loginPath } from '@/constants';
import { SelectEnv } from '@/components/SelectEnv/SelectEnv';
import { isShowSelectEnv } from '@/utils/changeEnv';
import { defaultLangUConfigMap } from '@/utils/locales';

export const toLogin = (isCleanSate?: boolean) => {
  const { query = {}, search, pathname } = history.location;
  console.log(history.location);
  const { redirect } = query;
  if (window.location.pathname !== loginPath && !redirect) {
    const newSearch: { isCleanSate?: boolean; redirect: string } = {
      redirect: `${pathname}${search}`,
    };
    if (isCleanSate) {
      newSearch.isCleanSate = true;
      logOut();
    }
    history.replace({
      pathname: loginPath,
      search: stringify(newSearch),
    });
  }
};

const Login: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const { initialState, setInitialState } = useModel('@@initialState');

  useEffect(() => {
    const { query = {} } = history.location;
    const { isCleanSate } = query;
    if (isCleanSate) {
      setInitialState((s: any) => ({ ...s, currentUser: undefined })).then();
      logOut();
    }
  }, [setInitialState]);

  const selectedLangs = initialState?.getLang.map((langCode) => defaultLangUConfigMap[langCode]);
  const postData = selectedLangs?.map((langData) => ({
    lang: langData.lang,
    label: langData.label,
    icon: langData.icon,
    title: langData.title,
  }));

  const intl = useIntl();

  const fetchUserInfo = async () => {
    const userInfo = await initialState?.fetchUserInfo?.();
    await setInitialState((s: any) => ({
      ...s,
      currentUser: userInfo,
    }));
  };

  const handleSubmit = async (values: API.LoginRequest) => {
    setSubmitting(true);
    try {
      const { data: res } = await login({ ...values });
      if (res) {
        saveAuthToken(res.accessToken);
        await fetchUserInfo();
        const defaultLoginSuccessMessage = intl.formatMessage({
          id: 'pages.login.success',
          defaultMessage: 'login successful!',
        });
        message.success(defaultLoginSuccessMessage);
        /** This method will jump to the location of the redirect parameter */
        if (!history) return;
        const { query } = history.location;
        const { redirect } = query as { redirect: string };
        history.push(redirect || '/');
        return;
      }
    } catch (error: any) {
      const defaultLoginFailureMessage = intl.formatMessage({
        id: 'pages.login.failure',
        defaultMessage: 'Login failed, please try again!',
      });
      message.error(defaultLoginFailureMessage);
    }
    setSubmitting(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.lang} data-lang>
        {initialState?.app === 'ap'
          ? // @ts-ignore
            SelectLang && <SelectLang postLocalesData={() => postData} />
          : SelectLang && <SelectLang />}
        {isShowSelectEnv() && <SelectEnv />}
      </div>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.header}>
            <Link to="/">
              <img alt="logo" className={styles.logo} src={`/icons/logo.png`} />
            </Link>
          </div>
          <div className={styles.desc} />
        </div>

        <div className={styles.main}>
          <ProForm
            initialValues={{}}
            submitter={{
              searchConfig: {
                submitText: intl.formatMessage({
                  id: 'pages.login.submit',
                  defaultMessage: 'Log in',
                }),
              },
              render: (_, dom) => dom.pop(),
              submitButtonProps: {
                loading: submitting,
                size: 'large',
                style: {
                  width: '100%',
                },
              },
            }}
            onFinish={async (values) => {
              await handleSubmit(values as API.LoginRequest);
            }}
          >
            {
              <>
                <ProFormText
                  fieldProps={{
                    size: 'large',
                    prefix: <UserOutlined className={styles.prefixIcon} />,
                  }}
                  name="phone"
                  placeholder={intl.formatMessage({
                    id: 'pages.login.phoneNumber.placeholder',
                    defaultMessage: 'Phone number',
                  })}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="pages.login.phoneNumber.required"
                          defaultMessage="Please enter phone number!"
                        />
                      ),
                    },
                  ]}
                />
                <ProFormText.Password
                  name="password"
                  fieldProps={{
                    size: 'large',
                    prefix: <LockOutlined className={styles.prefixIcon} />,
                  }}
                  placeholder={intl.formatMessage({
                    id: 'pages.login.password.placeholder',
                    defaultMessage: 'Password',
                  })}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="pages.login.password.required"
                          defaultMessage="please enter password!"
                        />
                      ),
                    },
                  ]}
                />
              </>
            }

            <div
              style={{
                marginBottom: 24,
              }}
            />
          </ProForm>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
