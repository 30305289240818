// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import CrownOutlined from '@ant-design/icons/es/icons/CrownOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import TagsOutlined from '@ant-design/icons/es/icons/TagsOutlined';
import PayCircleOutlined from '@ant-design/icons/es/icons/PayCircleOutlined';
import PhoneOutlined from '@ant-design/icons/es/icons/PhoneOutlined';
import MoneyCollectOutlined from '@ant-design/icons/es/icons/MoneyCollectOutlined';
import PropertySafetyOutlined from '@ant-design/icons/es/icons/PropertySafetyOutlined'
  export default {
    SmileOutlined,
CrownOutlined,
UserOutlined,
TagsOutlined,
PayCircleOutlined,
PhoneOutlined,
MoneyCollectOutlined,
PropertySafetyOutlined
  }