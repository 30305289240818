export default {
  'pages.creditReview.creditRecordManager.creditID': '授信ID',
  'pages.creditReview.creditRecordManager.IDNO': '证件号码',
  'pages.creditReview.creditRecordManager.creditLimit': '授信额度',
  'pages.creditReview.creditRecordManager.productType': '产品类型',
  'pages.creditReview.creditRecordManager.interestRateOne': '利率1',
  'pages.creditReview.creditRecordManager.interestRateTwo': '利率2',
  'pages.creditReview.creditRecordManager.creditStatus': '授信状态',
  'pages.creditReview.creditRecordManager.userChannels': '用户渠道',
  'pages.creditReview.FirstReview.manualAuditStatistics.auditQuantity': '审核件数',
  'pages.creditReview.FirstReview.manualAuditStatistics.onAuditQuantity': '审核中件数',
  'pages.creditReview.FirstReview.manualAuditStatistics.auditRejectedQuantity': '审核拒绝数',
  'pages.creditReview.FirstReview.manualAuditStatistics.auditPassQuantity': '审核通过数',
  'pages.creditReview.FirstReview.manualAuditStatistics.auditSupplementsQuantity': '审核补件数',
  'pages.creditReview.FirstReview.manualAuditStatistics.closeNum': '关闭件数',
  'pages.creditReview.FirstReview.manualAuditStatistics.rejectRate': '拒绝率',
  'pages.creditReview.FirstReview.manualAuditStatistics.replacementRate': '补件率',
  'pages.creditReview.FirstReview.manualAuditStatistics.passRate': '通过率',
  'pages.creditReview.FirstReview.manualAuditStatistics.approvalSuccessRate': '审核中率',
  'pages.creditReview.FirstReview.manualAuditStatistics.closeRate': '关闭率',
  'pages.creditReview.FirstReview.manualAuditStatistics.export': '导出',
  'pages.creditReview.FirstReview.auditReview.reviewStatus': '审核状态',
  'pages.creditReview.FirstReview.auditReview.enterAuditTime': '进入审核时间',
  'pages.creditReview.FirstReview.auditReview.release': '释放',
  'pages.creditReview.FirstReview.auditReview.continueReview': '继续审核',
  'pages.creditReview.FirstReview.auditReview.review': '审核',
  'pages.creditReview.FirstReview.auditedReview.creditSteps': '授信步骤',
  'pages.creditReview.FirstReview.auditedRevie.auditConclusion': '审核结论',
  'pages.creditReview.FirstReview.auditedRevie.auditReason': '审核理由',
  'pages.creditReview.FirstReview.auditedRevie.auditDuration': '审核时长',
  'pages.creditReview.creditRecordManager.CID': '用户ID',
  'page.creditReview.creditRecordManager.userName': '用户姓名',
  'page.creditReview.creditRecordManager.mobile': '用户手机号',
  'pages.creditReview.creditRecordManager.credentialType': '证件类型',
  'page.creditReview.creditRecordManager.statusUpdateTime': '状态更新时间',
  'page.creditReview.creditRecordManager.applyTime': '申请时间',
  'pages.creditReview.creditRecordManager.operatorName': '审核员姓名',
  'page.creditReview.creditRecordManager.reviewer': '审核员',
  'page.creditReview.creditRecordManager.conclusionManualReview': '人工审核结论',
  'page.creditReview.creditRecordManager.loanId': '借款ID',
  'page.creditReview.creditRecordManager.amount': '借款金额',
  'page.creditReview.creditRecordManager.loanStatus': '借款状态',
  'page.creditReview.creditRecordManager.finalApprovedTime': '终审通过时间',
  'page.creditReview.creditRecordManager.issueSuccessTime': '放款成功时间',
  'pages.creditReview.FirstReview.manualAuditStatistics.date': '日期',
  'pages.creditReview.FirstReview.manualAuditStatistics.markingCompletedParts': '打标完成件',
  'pages.creditReview.FirstReview.manualAuditStatistics.complianceRate': '打标率',
  'pages.creditReview.creditRecordManager.action': '操作',
  'common.read': '查看',
  'pages.creditReview.FirstReview.auditedRevie.remark': '备注',
  'pages.creditReview.FirstReview.auditedRevie.customerCallTag': '客户拨打tag',
  'pages.creditReview.FirstReview.auditedRevie.contactOneDialsTag': '联系人1 拨打tag',
  'pages.creditReview.FirstReview.auditedRevie.contactTwoDialsTag': '联系人2 拨打tag',
  'pages.creditReview.FirstReview.auditedRevie.contactThreeDialsTag': '联系人3 拨打tag',
  'pages.creditReview.creditRecordDetail.photoSimilarity': '证件照与活体对比相似度',
  'pages.creditReview.FirstReview.auditedReview.reviewTime': '审核时间',
  'pages.creditReview.creditRecordDetail.realName': '客户姓名',
  'pages.creditReview.creditRecordDetail.credentialType': '证件类型',
  'pages.creditReview.creditRecordDetail.cardNumber': '证件号码',
  'pages.creditReview.creditRecordDetail.dateBirth': '出生日期',
  'pages.creditReview.creditRecordDetail.gender': '性别',
  'pages.creditReview.creditRecordDetail.age': '年龄',
  'pages.creditReview.creditRecordDetail.maritalStatus': '婚姻状态',
  'pages.creditReview.creditRecordDetail.purposeBorrowing': '借款目的',
  'pages.creditReview.creditRecordDetail.educationalLevel': '受教育程度',
  'pages.creditReview.creditRecordDetail.numberCreditCards': '信用卡数量',
  'pages.creditReview.creditRecordDetail.area': '区域（省市区）',
  'pages.creditReview.creditRecordDetail.livingAddress': '详细地址',
  'pages.creditReview.creditRecordDetail.email': '邮箱地址',
  'pages.creditReview.creditRecordDetail.profession': '职业',
  'pages.creditReview.creditRecordDetail.salary': '月收入',
  'pages.creditReview.creditRecordDetail.payPeriod': '收入周期',
  'pages.creditReview.creditRecordDetail.salaryDay': '发薪日',
  'pages.creditReview.creditRecordDetail.contactName': '联系人姓名',
  'pages.creditReview.creditRecordDetail.contactPhone': '联系人电话',
  'pages.creditReview.creditRecordDetail.relationship': '联系人关系',
  'pages.creditReview.creditRecordDetail.updateTime': '更新时间',
  'pages.creditReview.creditRecordDetail.call': '拨打功能',
  'pages.creditReview.creditRecordDetail.addCallTag': '增加拨打标签',
  'pages.creditReview.creditRecordDetail.contactCallTag': '拨打标签',
  'pages.creditReview.creditRecordDetail.firstRegisterTime': '首次注册时间',
  'pages.creditReview.creditRecordDetail.firstTransactionTime': '首次交易时间',
  'pages.creditReview.creditRecordDetail.customerId': '客户号',
  'pages.creditReview.creditRecordDetail.mobile': '手机号',
  'pages.creditReview.creditRecordDetail.status': '状态',
  'pages.creditReview.creditRecordDetail.createTime': '创建时间',
  'pages.creditReview.creditRecordDetail.manualCalibration': '证件类型-人工校准',
  'pages.creditReview.creditRecordDetail.usedAmount': '已使用额度',
  'pages.creditReview.creditRecordDetail.reviewingAmount': '审核中额度',
  'pages.creditReview.creditRecordDetail.availableAmount': '剩余可用额度',
  'pages.creditReview.creditRecordDetail.interestRate': '产品日利率',
  'pages.creditReview.creditRecordDetail.effectiveTime': '额度生效时间',
  'pages.creditReview.creditRecordDetail.ecpirationTime': '额度失效时间',
  'pages.creditReview.creditRecordDetail.name': '姓名',
  'pages.creditReview.creditRecordDetail.dateExpiry': '到期日期',
  'pages.creditReview.creditRecordDetail.dateIssue': '发证日期',
  'pages.creditReview.creditRecordDetail.address': '地址',
  'pages.creditReview.creditRecordDetail.eyesColor': '眼睛颜色',
  'pages.creditReview.creditRecordDetail.issuingStateName': '发证国家名称',
  'pages.creditReview.creditRecordDetail.issuingStateCode': '发证国家编码',
  'pages.creditReview.creditRecordDetail.nationality': '国籍',
  'pages.creditReview.creditRecordDetail.result': '结论',
  'pages.creditReview.creditRecordDetail.callCustomer': '致电客户',
  'pages.creditReview.creditRecordDetail.personalInfo': '个人信息',
  'pages.creditReview.creditRecordDetail.employmentInfo': '就业信息',
  'pages.creditReview.creditRecordDetail.contactInfo': '联系信息',
  'pages.creditReview.creditRecordDetail.historyAccountInfo': '历史账户信息',
  'pages.creditReview.creditRecordDetail.productInfo': '产品信息',
  'pages.creditReview.creditRecordDetail.addCustomeCallingTag': '添加客户呼叫标签',
  'pages.creditReview.creditRecordDetail.customerCallingTag': '客户呼叫标签',
  'menu.creditReview.FirstReview.auditedReview.manualCalibration': '人工校准',
  'pages.creditReview.creditRecordDetail.addIDTag': '添加ID标签',
  'pages.creditReview.creditRecordDetail.IDTag': 'ID标签',
  'pages.creditReview.creditRecordDetail.reason': '原因',
  'pages.creditReview.creditRecordDetail.IDAnalyzeResult': 'ID分析结果',
  'pages.creditReview.creditRecordDetail.loanHistory': '贷款历史',
  'pages.creditReview.creditRecordDetail.BioSimilarity': '生物相似性',
  'pages.creditReview.creditRecordDetail.loanID': '贷款id',
  'pages.creditReview.creditRecordDetail.loanApplyTime': '申请放款时间',
  'pages.creditReview.creditRecordDetail.loanDisbursementTime': '放款成功时间',
  'pages.creditReview.creditRecordDetail.loanStatus': '贷款状态',
  'pages.creditReview.creditRecordDetail.walletAccount': '放款账号',
  'pages.creditReview.creditRecordDetail.walletChannelOne': '放款渠道1',
  'pages.creditReview.creditRecordDetail.walletChannelTwo': '放款渠道2',
  'pages.creditReview.creditRecordDetail.amount': '金额',
  'pages.creditReview.creditRecordDetail.customerID': '用户ID',
  'pages.creditReview.creditRecordDetail.similarityScore': '相似度评分',
  'pages.creditReview.creditRecordDetail.loanType': '借款类型',
  'pages.creditReview.creditRecordDetail.productName': '产品名',
  'pages.creditReview.creditRecordDetail.switchManualReason': '转人工理由',
  'pages.creditReview.creditRecordDetail.rejectReason': '拒绝理由',
  'pages.creditReview.creditRecordDetail.isWhiteListCustomer': '是否白名单用户',
  'pages.creditReview.creditRecordDetail.creditApplyStatus': '授信申请状态',
  'pages.creditReview.creditRecordDetail.creditAppStep': '授信所在步骤',
  'pages.creditReview.creditRecordDetail.reviewerId': '审核员id',
  'pages.creditReview.creditRecordDetail.startReviewTime': '进入人审时间',
  'pages.creditReview.creditRecordDetail.reviewResultTime': '人审下结论时间',
  'pages.creditReview.creditRecordDetail.reviewResult': '人审结论',
  'pages.creditReview.creditRecordDetail.idTagReason': '证件tag理由',
  'pages.creditReview.creditRecordDetail.customerTagReason': '客户tag理由',
  'pages.creditReview.creditRecordDetail.contactOneTagReason': '联系人1 tag理由',
  'pages.creditReview.creditRecordDetail.contactTwoTagReason': '联系人2 tag理由',
  'pages.creditReview.creditRecordDetail.contactThreeTagReason': '联系人3 tag理由',
  'pages.creditReview.creditRecordDetail.creditApplyTime': '授信申请时间',
  'pages.creditReview.creditRecordDetail.tagContent': 'tag内容',
  'pages.creditReview.creditRecordDetail.tagTime': 'tag时间',
  'pages.creditReview.creditRecordDetail.callName': '拨打姓名',
  'pages.creditReview.creditRecordDetail.callNumber': '拨打号码',
  'pages.creditReview.creditRecordDetail.Relationship': '关系',
  'pages.creditReview.creditRecordDetail.callResult': '拨打结果',
  'pages.creditReview.creditRecordDetail.beginCallTime': '拨打时间',
  'pages.creditReview.creditRecordDetail.objectName': '对象姓名',
  'pages.creditReview.creditRecordDetail.tagType': '类型',
  'pages.creditReview.creditRecordDetail.tagReason': 'tag理由',
  'pages.creditReview.creditRecordDetail.btn.pass': '通过',
  'pages.creditReview.creditRecordDetail.btn.reject': '拒绝',
  'pages.creditReview.creditRecordDetail.btn.tagCompleted': '标记已完成',
  'pages.creditReview.creditRecordDetail.comments': '评论',
  'pages.userManage.userFeedback.feedbackTime': '反馈时间',
  'pages.userManage.userFeedback.feedbackImage': '反馈图片',
  'pages.userManage.userFeedback.feedbackContent': '反馈内容',
  'pages.creditReview.FirstReview.auditReview.lockReview': '当前案件将被你锁定请尽快审核！',
  'pages.creditReview.FirstReview.auditReview.releaseLockCase': '是否确定将当前锁定案件进行释放？',
  'pages.creditReview.creditRecordDetail.addTagBtnMessage': '添加成功',
  'pages.creditReview.creditRecordDetail.detailReason': '详细原因',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.by': '功能调用方',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.message': '信息',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.surname': '姓',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.middleName': '中间名',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.givenName': '名',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.province': '省',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.city': '市',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.homeAddress': '居住地址',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.service': '服务商',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.reuslt': '返回结果',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.returnMessage': '返回信息',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.documentNumber': '文档号码',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.issuerAuthority': '发行机构',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.documentType': '文档类别代码',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.birthPlace': '出生地点',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.issuingAuthority': '发行权威',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.issueDateParsed': '发证日期',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.weight': '体重',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.restrictions': '驾照限制代码',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.agencyCode': '机构代码',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.bloodType': '血型',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.conditions': '条件',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.height': '身高',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.occupation': '专业',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.precinctNo': '选区',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.civilStatus': '公民状态',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.chairman': '主席',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.postCode': '邮政编码',
  'pages.creditReview.creditRecordDetail.idAnalyzeInfo.countryCode': '发行国家代码',
  'pages.creditReview.creditRecordDetail.periodInterestRate': '期利率',
  'pages.creditReview.creditRecordManager.interestRate': '利率',
  'common.detail': '查看详情',
  'pages.collection.my.log.caseId': 'Case Id',
  'pages.collection.my.log.classification': 'Classification',
  'pages.collection.my.log.comment': '备注',
  'pages.collection.my.log.customerId': '用户ID',
  'pages.collection.my.log.description': '描述',
  'pages.collection.my.log.level': '级别',
  'pages.collection.my.log.name': 'TagName',
  'pages.collection.my.log.parentId': '父ID',
  'pages.collection.my.log.status': '状态',
  'pages.collection.my.log.type': '类型',
  'pages.collection.my.log.updateId': '更新Id',
  'pages.collection.my.log.updateName': '更新名称',
  'pages.collection.my.logTime': '更新日志时间',
  'pages.collection.my.mobiles.backupMobile': '备用手机号',
  'pages.collection.my.mobiles.caseType': '案件类型',
  'pages.collection.my.mobiles.collectionlD': '分案ID',
  'pages.collection.my.mobiles.email': '用户邮箱',
  'pages.collection.my.mobiles.level': '用户催收等级',
  'pages.collection.my.mobiles.mobile': '用户手机号',
  'pages.collection.my.mobiles.name': '用户姓名',
  'pages.collection.my.mobiles.overdueDays': '案件当前逾期天数',
  'pages.collection.my.multHeaderInfo': '多头信息',
  'pages.collection.my.openNewTab': '新页面打开',
  'pages.collection.my.phoneNumber': '手机号',
  'pages.collection.my.post': '邮件',
  'pages.collection.my.ptp': 'Ptp',
  'pages.collection.my.reviewerId': '催收员id',
  'pages.collection.my.riskGrade': '风险等级',
  'pages.collection.my.searchDate': '查询日期',
  'pages.collection.my.sendsms': '发送短信',
  'pages.collection.my.sevenDayMultHeaderNum': '近7天多头数',
  'pages.collection.my.specialDivision.tip': '必须要输入催收员id或者选择催收员',
  'pages.collection.my.status': '状态',
  'pages.collection.my.teamLeaderName': '组长姓名',
  'pages.collection.my.template': '消息模板',
  'pages.collection.my.thirdpartyName': '三方名称',
  'pages.collection.my.toDetail': '批量查看详情',
  'pages.collection.my.trigger': 'Trigger',
  'pages.collection.my.userId': '用户ID',
  'pages.collection.my.username': '用户姓名',
  'pages.collection.my.userOverdueDays': '用户逾期天数',
  'pages.collection.my.caseId': 'Case Id',
  'pages.collection.my.clearedNumber': '结清期数',
  'pages.collection.my.collector': '催收员',
  'pages.collection.my.contactMobiles': '联系人手机号',
  'pages.collection.my.currentPrincipalToBeTepaid': '当前待还本金',
  'pages.collection.my.customerType': '用户类型',
  'pages.collection.my.detail.accountHolderName': '帐户持有人姓名',
  'pages.collection.my.detail.firstTransactionTime': '首次交易时间',
  'pages.collection.my.detail.lastCreditTime': '最近一次提交授信时间',
  'pages.collection.my.detail.lastCreditPassTime': '最近一次授信通过时间',
  'pages.collection.my.detail.address': 'KTP解析地址',
  'pages.collection.my.detail.adminName': '管理员名称',
  'pages.collection.my.detail.adminNamed': 'Admin Named',
  'pages.collection.my.detail.amount': '放款金额',
  'pages.collection.my.detail.amountCurrentlyPending': '当前待还金额',
  'pages.collection.my.detail.applicationId': '订单id',
  'pages.collection.my.detail.applicationID': '申请 ID',
  'pages.collection.my.detail.applicationLog': '应用程序日志',
  'pages.collection.my.detail.applicationLogd': 'Application Logd',
  'pages.collection.my.detail.applicationStatus': '状态',
  'pages.collection.my.detail.applyTime': '申请时间',
  'pages.collection.my.detail.appMobileInfo': '应用移动信息',
  'pages.collection.my.detail.appointmentDate': '承诺还款时间',
  'pages.collection.my.detail.appointmentDated': 'Appointment Dated',
  'pages.collection.my.detail.arrivedAmount': '到账金额',
  'pages.collection.my.detail.backupMobile': '备用用户手机号',
  'pages.collection.my.detail.bankBranch': '银行分支机构',
  'pages.collection.my.detail.bankcardInfo': '银行卡信息',
  'pages.collection.my.detail.bankCode': '银行',
  'pages.collection.my.detail.birthday': '出生日期',
  'pages.collection.my.detail.calculateType': '减免类型',
  'pages.collection.my.detail.calculateType.all': '全部结清',
  'pages.collection.my.detail.calculateType.part': '逾期还款',
  'pages.collection.my.detail.callMobile': 'CallMobile',
  'pages.collection.my.detail.callName': 'CallName',
  'pages.collection.my.detail.callRelation': 'CallRelation',
  'pages.collection.my.detail.cardNumber': '卡号',
  'pages.collection.my.detail.channel': '通道',
  'pages.collection.my.detail.childrenNumber': '孩子的数量',
  'pages.collection.my.detail.city': '城市',
  'pages.collection.my.detail.classification': 'Classification',
  'pages.collection.my.detail.clearedAmount': '清算金额',
  'pages.collection.my.detail.collectionLevel': '催收等级',
  'pages.collection.my.detail.collectionMethod': '催收方式',
  'pages.collection.my.detail.collectionTask': '收集任务',
  'pages.collection.my.detail.collectionTaskd': 'Collection Taskd',
  'pages.collection.my.detail.collectorName': '催收员',
  'pages.collection.my.detail.comment': 'comment',
  'pages.collection.my.detail.comments': '备注',
  'pages.collection.my.detail.commentsd': 'Commentsd',
  'pages.collection.my.detail.companyAddress': '公司地址',
  'pages.collection.my.detail.companyArea': '公司区域',
  'pages.collection.my.detail.companyCity': '公司市',
  'pages.collection.my.detail.companyDistrict': '公司地区',
  'pages.collection.my.detail.companyName': '公司名称',
  'pages.collection.my.detail.companyPhone': '公司电话',
  'pages.collection.my.detail.companyProvince': '公司省',
  'pages.collection.my.detail.complaintsContent': '投诉内容',
  'pages.collection.my.detail.complaintsInfo': '投诉信息',
  'pages.collection.my.detail.complaintsNumber': 'ComplaintsNumber',
  'pages.collection.my.detail.conclusion': '结论',
  'pages.collection.my.detail.contactInfo': '联系人信息',
  'pages.collection.my.detail.couponAmount': '免息券金额',
  'pages.collection.my.detail.couponCode': '券编号',
  'pages.collection.my.detail.couponEndTime': '结束时间',
  'pages.collection.my.detail.couponInterestThan': 'Coupon Interest Than',
  'pages.collection.my.detail.couponStartTime': '开始时间',
  'pages.collection.my.detail.couponStatus': '券状态',
  'pages.collection.my.detail.couponStructure': '券结构',
  'pages.collection.my.detail.couponType': '券类型',
  'pages.collection.my.detail.createTime': '创建时间',
  'pages.collection.my.detail.createTimed': 'Create Timed',
  'pages.collection.my.detail.credentialType': '证件类型',
  'pages.collection.my.detail.creditQuality': '信用质量',
  'pages.collection.my.detail.currentBreakPromiseNumber': '失信次数',
  'pages.collection.my.detail.currentPeriod': '本期',
  'pages.collection.my.detail.customerId': '客户id',
  'pages.collection.my.detail.customerID': '用户 ID',
  'pages.collection.my.detail.customerMobile': '用户电话',
  'pages.collection.my.detail.customerVersionType': 'CustomerVersionType',
  'pages.collection.my.detail.defaultAccr': '应还罚息',
  'pages.collection.my.detail.defaultFeeAccr': '应还罚息',
  'pages.collection.my.detail.defaultFeePaid': '已清算罚息',
  'pages.collection.my.detail.defaultPaid': '已清算罚息',
  'pages.collection.my.detail.defaultRealPaid': '罚息真实已还金额',
  'pages.collection.my.detail.defaultReduction': '罚息减免金额',
  'pages.collection.my.detail.district': '区',
  'pages.collection.my.detail.dueAmount': '待还金额',
  'pages.collection.my.detail.dueDate': '到期日期',
  'pages.collection.my.detail.dueLeftAmount': '待还罚息',
  'pages.collection.my.detail.dueTotalAmount': '待还本金',
  'pages.collection.my.detail.duration': '借款期限',
  'pages.collection.my.detail.educationalLevel': '教育水平',
  'pages.collection.my.detail.email': '电子邮件',
  'pages.collection.my.detail.employmentInfo': '工作信息',
  'pages.collection.my.detail.eraseAmount': '减免金额',
  'pages.collection.my.detail.expireAmount': '当前逾期待还金额',
  'pages.collection.my.detail.expireInterestAmount': '当前逾期待还利息',
  'pages.collection.my.detail.expirePrincipleAmount': '当前逾期待还本金',
  'pages.collection.my.detail.expirePunishmentAmount': '当前逾期待还罚息',
  'pages.collection.my.detail.facebookId': 'facebook id',
  'pages.collection.my.detail.gender': '性别',
  'pages.collection.my.detail.gracePeriodRate': '宽限期率',
  'pages.collection.my.detail.historyAccountInfo': '历史账户信息',
  'pages.collection.my.detail.historyApplications': '历史应用程序',
  'pages.collection.my.detail.iatestComment': 'LatestComment',
  'pages.collection.my.detail.ibsAddress': 'Ibs Address',
  'pages.collection.my.detail.id': 'Id',
  'pages.collection.my.detail.iiveTime': 'Iive Time',
  'pages.collection.my.detail.iivingAddress': '居住地址',
  'pages.collection.my.detail.interestAccr': '应还利息',
  'pages.collection.my.detail.interestAmount': '待还利息',
  'pages.collection.my.detail.interestFreeStamps': 'Interest FreeStamps',
  'pages.collection.my.detail.interestPaid': '已清算利息',
  'pages.collection.my.detail.interestRate': '利率',
  'pages.collection.my.detail.interestRealPaid': '利息真实已还金额',
  'pages.collection.my.detail.interestReduction': '利息减免金额',
  'pages.collection.my.detail.iPayId': 'lpayid',
  'pages.collection.my.detail.ipayStatus': 'lpay状态',
  'pages.collection.my.detail.isRepayCustomer': '是否还款',
  'pages.collection.my.detail.issueAmount': '放款金额',
  'pages.collection.my.detail.issueMethod': '放款方式',
  'pages.collection.my.detail.issueStatus': '放款状态',
  'pages.collection.my.detail.issueSuccessCount': '借款次数',
  'pages.collection.my.detail.isWhiteListCustomer': '白名单',
  'pages.collection.my.detail.lastPunishmentTime': '罚息更新时间',
  'pages.collection.my.detail.level': '催收等级',
  'pages.collection.my.detail.loanAppId': 'Loan App Id',
  'pages.collection.my.detail.loanCoupon': '贷款优惠券',
  'pages.collection.my.detail.loanId': '订单id',
  'pages.collection.my.detail.loanInfo': '贷款信息',
  'pages.collection.my.detail.LoanInfo(CURRENT)': 'Loan Info(CURRENT)',
  'pages.collection.my.detail.loanIssueHistory': '放款历史',
  'pages.collection.my.detail.loanIssueId': '放款id',
  'pages.collection.my.detail.LoanStatus': '保证金状态',
  'pages.collection.my.detail.LoanSubType': '贷款的子类型',
  'pages.collection.my.detail.loanType': '借款类型',
  'pages.collection.my.detail.logId': '日志id',
  'pages.collection.my.detail.logIdd': 'Log Idd',
  'pages.collection.my.detail.marginOfCredit': '保证金',
  'pages.collection.my.detail.maritalStatus': '婚姻状况',
  'pages.collection.my.detail.mobile': '手机号',
  'pages.collection.my.detail.motherMiddleName': '妈妈的姓',
  'pages.collection.my.detail.name': '联系人名字',
  'pages.collection.my.detail.newCollectionLog': '新收集日志',
  'pages.collection.my.detail.oldCollectionLog': '旧收集日志',
  'pages.collection.my.detail.outCallsLog': '外呼日志',
  'pages.collection.my.detail.operator': '操作人',
  'pages.collection.my.detail.operatorComment': '备注',
  'pages.collection.my.detail.operatord': 'Operatord',
  'pages.collection.my.detail.operatorName': '操作人',
  'pages.collection.my.detail.outstandingInterest': '未到期利息',
  'pages.collection.my.detail.outstandingPrincipalAmount': '未到期本金',
  'pages.collection.my.detail.overdueDays': '逾期天数',
  'pages.collection.my.detail.overdueFeeRate': '逾期服务费',
  undefined: '管理员ID',
  'pages.collection.my.detail.overdueRate': '逾期原因',
  'pages.collection.my.detail.overdueReason': '结清金额',
  'pages.collection.my.detail.paidOffTime': '结清时间',
  'pages.collection.my.detail.penaltyFactory': 'Penalty Factory',
  'pages.collection.my.detail.personalInfo': '个人信息',
  'pages.collection.my.detail.phone': '电话',
  'pages.collection.my.detail.phoneNumber': '手机号',
  'pages.collection.my.detail.ppn': 'PPN',
  'pages.collection.my.detail.principalAccr': '应还本金',
  'pages.collection.my.detail.principalPaid': '已清算本金',
  'pages.collection.my.detail.principalRealPaid': '本金真实已还金额',
  'pages.collection.my.detail.principalReduction': '本金减免金额',
  'pages.collection.my.detail.principleAmount': '待还本金',
  'pages.collection.my.detail.productName': 'Product Name',
  'pages.collection.my.detail.profession': '职业',
  'pages.collection.my.detail.province': '省',
  'pages.collection.my.detail.punishmentAmount': '待还罚息',
  'pages.collection.my.detail.quickSelectionOfCollectionCases': '快速选择催收案件',
  'pages.collection.my.detail.randomId': '随机id',
  'pages.collection.my.detail.reachedAmount': '到账金额',
  'pages.collection.my.detail.realName': '姓名',
  'pages.collection.my.detail.reduceAmount': '减免金额',
  'pages.collection.my.detail.reduceType': '减免方案',
  'pages.collection.my.detail.relation': 'Relation',
  'pages.collection.my.detail.relationship': '关系',
  'pages.collection.my.detail.RemissionInformation': '减免信息',
  'pages.collection.my.detail.repaymentAmount': '还款金额',
  'pages.collection.my.detail.repaymentChannel': '还款渠道',
  'pages.collection.my.detail.repaymentHistory': '还款清算历史',
  'pages.collection.my.detail.repaymentId': '还款id',
  'pages.collection.my.detail.repaymentMethod': '还款方式',
  'pages.collection.my.detail.repaymentPlan': '还款计划',
  'pages.collection.my.tab.repayRecord': '还款记录',
  'page.review.creditRecordManager.detail.loanHistory': '借款历史',
  'pages.collection.my.detail.repaymentStatus': '还款状态',
  'pages.collection.my.detail.reviewer': 'Reviewer',
  'pages.collection.my.detail.rolloverFeeAccr': '应还展期费',
  'pages.collection.my.detail.rolloverFeePaid': '已还展期费',
  'pages.collection.my.detail.rolloverLeftAmount': '待还利息',
  'pages.collection.my.detail.rolloverPrincipalAccr': '应还展期本金',
  'pages.collection.my.detail.rolloverPrincipalPaid': '已还展期本金',
  'pages.collection.my.detail.rolloverPrincipalRate': '展期应付本金比例',
  'pages.collection.my.detail.rolloverTimes': '展期时间',
  'pages.collection.my.detail.salary': '工资',
  'pages.collection.my.detail.salaryDay': '发薪日',
  'pages.collection.my.detail.serviceFeeAccr': '手续费应还金额',
  'pages.collection.my.detail.serviceFeePaid': '手续费已还金额',
  'pages.collection.my.detail.SIMCARDMOBILE1': 'SIM CARD MOBILE1',
  'pages.collection.my.detail.SIMCARDMOBILE2': 'SIM CARD MOBILE2',
  'pages.collection.my.detail.status': '状态',
  'pages.collection.my.detail.stepExecId': '执行id',
  'pages.collection.my.detail.subStatus': '子状态',
  'pages.collection.my.detail.taskId': '任务id',
  'pages.collection.my.detail.taskIdd': 'Task Idd',
  'pages.collection.my.detail.taskStatus': '任务状态',
  'pages.collection.my.detail.taskStatusd': 'Task Statusd',
  'pages.collection.my.detail.taskType': '任务类型',
  'pages.collection.my.detail.taskTyped': 'Task Typed',
  'pages.collection.my.detail.throttleId': 'Throttle Id',
  'pages.collection.my.detail.throttleReason': '原因',
  'pages.collection.my.detail.throttleType': '类型',
  'pages.collection.my.detail.totalBreakPromiseNumber': '总失信次数',
  'pages.collection.my.detail.totalPeriod': '总期数',
  'pages.collection.my.detail.updateTime': '更新时间',
  'pages.collection.my.detail.userLevel': '用户等级',
  'pages.collection.my.detail.username': '用户名',
  'pages.collection.my.detail.userPhoneStatus': '用户电话状态',
  'pages.collection.my.detail.vatAccr': '应还vat',
  'pages.collection.my.detail.vatPaid': '已还vat',
  'pages.collection.my.detail.vatRealPaid': 'vat真实已清算金额',
  'pages.collection.my.detail.vatReduction': 'vat减免金额',
  'pages.collection.my.detail.verifyStatus': '验证状态',
  'pages.collection.my.detail.workEmail': '工作邮箱',
  'pages.collection.my.detail.workStartFrom': '工作时长',
  'pages.collection.my.distributedDays': '已分发天数',
  'pages.collection.my.distributionId': '分案ID',
  'pages.collection.my.goToDetail': '查看详情',
  'pages.collection.my.groupId': '组id',
  'pages.collection.my.groupName': '催收组',
  'pages.collection.my.lastLogCreateTime': '最后更新日志时间',
  'pages.collection.my.level': '催收等级',
  'pages.collection.my.collectionTag': '催收标签',
  'pages.collection.my.lastRepaytime': '最近还款时间',
  'pages.collection.my.lastCollectime': '最近催记时间',
  'pages.collection.my.dividetime': '分案时间',
  'common.option': '操作',
  'pages.userManage.userList.userPhone': '用户电话',
  'pages.userManage.userList.userStatus': '用户状态',
  'pages.userManage.userList.registerDate': '注册日期',
  'pages.userManage.userList.oneLevelChannel': '一级渠道',
  'pages.userManage.userList.secondaryChannels': '二级渠道',
  'pages.userManage.userList.loanAmount': '在贷金额',
  'pages.userManage.userList.registerTime': '注册时间',
  'pages.userManage.userList.detail.personalInfo': '个人信息',
  'pages.userManage.userList.detail.workInfo': '工作信息',
  'pages.userManage.userList.detail.contactsInfo': '联系人信息',
  'pages.userManage.userList.detail.documentInfo': '证件信息',
  'pages.userManage.userList.detail.basicInfo': '基础信息',
  'pages.userManage.userList.detail.fillingCapitalInfo': '填资信息',
  'pages.userManage.userList.detail.loanPurpose': '借款目的',
  'pages.userManage.userList.detail.creditCardsNum': '信用卡数量',
  'pages.userManage.userList.detail.emailAddress': '邮箱地址',
  'pages.userManage.userList.detail.residentialAreaProvince': '居住地区-省',
  'pages.userManage.userList.detail.residentialAreaCity': '居住地区-市',
  'pages.userManage.userList.detail.residentialAreaDistrict': '居住地区-区',
  'pages.userManage.userList.detail.residentialAreaDetailedAddress': '居住地区-详细地址',
  'pages.userManage.userList.detail.workOccupation': '工作职业',
  'pages.userManage.userList.detail.workIndustry': '工作行业',
  'pages.userManage.userList.detail.monthlyIncome': '每月收入',
  'pages.userManage.userList.detail.monthlyPayday': '每月发薪日',
  'pages.userManage.userList.detail.contactRelationship': '联系人关系',
  'pages.userManage.userList.detail.contactName': '联系人姓名',
  'pages.userManage.userList.detail.contactPhone': '联系人电话',
  'pages.userManage.userList.detail.imageFront': '证件图片（正面）',
  'pages.userManage.userList.detail.imageBack': '证件图片（反面）',
  'pages.userManage.userList.detail.paperID': '证件id',
  'pages.userManage.userList.detail.paperName': '证件姓名',
  'pages.userManage.userList.detail.periodValidity': '证件有效期',
  'pages.userManage.userList.detail.tripartiteFeedback': '三方回传',
  'pages.userManage.userList.detail.identeConclusion': '识别结论',
  'pages.userManage.userList.detail.tongDunOCR': 'OCR',
  'pages.userManage.userList.detail.livePictures': '活体图片',
  'pages.userManage.userList.detail.liveVideo': '活体视频',
  'pages.userManage.userList.detail.limitInfo': '额度信息',
  'pages.userManage.userList.detail.totalLimit': '总额度',
  'pages.userManage.userList.detail.onLoanLimit': '在贷额度',
  'pages.userManage.userList.detail.availablLimit': '可用额度',
  'pages.userManage.userList.detail.orderRecord': '订单记录',
  'pages.userManage.userList.detail.creditRecords': '授信记录',
  'pages.userManage.userList.detail.withdrawalRecord': '提现记录',
  'pages.userManage.userList.detail.repaymentPlan': '还款计划',
  'pages.userManage.userList.detail.paymentHistory': '还款记录',
  'pages.userManage.userList.detail.creditType': '授信类型',
  'pages.userManage.userList.detail.limitChange': '额度变化',
  'pages.userManage.userList.detail.orderId': '订单id',
  'pages.userManage.userList.detail.creditTime': '授信时间',
  'pages.userManage.userList.detail.performanceStatus': '履约状态',
  'pages.userManage.userList.detail.loanTime': '借款时间',
  'pages.userManage.userList.detail.repaymentPlanId': '还款计划id',
  'pages.userManage.userList.detail.paymentDate': '还款日',
  'pages.userManage.userList.detail.status': '状态',
  'pages.userManage.userList.detail.payableAmount': '应还金额',
  'pages.userManage.userList.detail.repaidAmount': '已还金额',
  'pages.userManage.userList.detail.payablePrincipal': '应还本金',
  'pages.userManage.userList.detail.repaidPrincipal': '已还本金',
  'pages.userManage.userList.detail.payableInterest': '应还利息',
  'pages.userManage.userList.detail.interestPaid': '已还利息',
  'pages.userManage.userList.detail.payableServiceFee': '应还服务费',
  'pages.userManage.userList.detail.paidServiceFee': '已还服务费',
  'pages.userManage.userList.detail.payableOverdueInterest': '应还逾期利息',
  'pages.userManage.userList.detail.overdueInterestRepayable': '已还逾期利息',
  'pages.userManage.userList.detail.dueOverduePenaltyInterest': '应还逾期罚息',
  'pages.userManage.userList.detail.overdueInterestRepaid': '已还逾期罚息',
  'pages.userManage.userList.detail.finalRepaymentDate': '最后还款日期',
  'pages.userManage.userList.detail.repaymentStatementID': '还款流水id',
  'pages.userManage.userList.detail.repaymentSubChannels': '还款子渠道',
  'pages.userManage.userList.detail.repaymentCode': '还款码',
  'pages.userManage.userList.detail.repaymentTime': '还款时间',
  'pages.userManage.userList.detail.repaymentPrincipal': '还款本金',
  'pages.userManage.userList.detail.repaymentInterest': '还款利息',
  'pages.userManage.userList.detail.repaymentServiceFee': '还款服务费',
  'pages.userManage.userList.detail.lateRepaymentInterest': '还款逾期利息',
  'pages.userManage.userList.detail.latePenaltyInterest': '还款逾期罚息',
  'commom.close': '关闭',
  'pages.collection.my.detail.lastestRecordTag': '最近催记标签',
  'pages.collection.my.detail.lastestRecordTime': '最近催记时间',
  'pages.collection.my.detail.lastestRepayAmount': '最近还款金额',
  'pages.collection.my.detail.lastestRepayTime': '最近还款时间',
  'pages.loanManage.loanRecords.product': '产品',
  'pages.loanManage.loanRecords.feeRate': '费率',
  'pages.loanManage.loanRecords.actualLoanAmount': '实际放款金额',
  'pages.loanManage.loanRecords.orderDueDate': '订单到期日',
  'pages.loanManage.loanRecords.totalInterestPayable': '应还总利息',
  'pages.loanManage.loanRecords.thirdTransactionID': '三方交易ID',
  'pages.loanManage.loanRecords.paymentSystemID': '支付系统ID',
  'pages.loanManage.loanRecords.threeDisbursementTime': '三方返回真实放款时间',
  'pages.loanManage.loanRecords.successfulTime': '放款成功时间',
  'pages.loanManage.loanRecords.paymentChannels': '支付渠道',
  'pages.loanManage.loanRecords.paymentCardNumber': '收款卡号',
  'pages.loanManage.loanRecords.nameReceiveCard': '收款卡姓名',
  'pages.loanManage.loanRecords.receiveCardBank': '收款卡银行',
  'common.close': '关闭',
  'pages.developer.indicatorManagement.modelManage.status': '状态',
  'pages.developer.indicatorManagement.modelManage.modelCode': '模型code',
  'pages.developer.indicatorManagement.modelManage.stateSwitch': '状态切换',
  'common.updateor': '更新人',
  'common.updateTime': '更新时间',
  'pages.developer.indicatorManagement.modelManage.remark': '备注',
  'common.createTime': '创建时间',
  'common.creator': '创建人',
  'common.edit': '编辑',
  'common.delete': '删除',
  'common.create': '新建',
  'pages.collection.caseDetail.callContact': '拨打联系人电话',
  'pages.collection.caseDetail.callBorrower': '拨打借款人电话',
  'pages.collection.caseDetail.beforeCallingTip': '确定拨打该电话？',
  'page.collection.detail.collectionLog.firstLevelTag': '一级标签',
  'page.collection.detail.collectionLog.secondLevelTag': '二级标签',
  'page.collection.detail.collectionLog.thirdLevelTag': '三级标签',
  'page.collection.detail.collectionLog.comment': '催收备注',
  'common.operatorName': '操作人',
  'common.overdueDays': '逾期天数',
  'common.status': '状态',
  'common.serviceLine': '业务线',
  'common.view': '详情',
  'page.collection.detail.borrowOrderId': '借款订单ID',
  'page.collection.detail.orderStatus': '订单状态',
  'page.collection.detail.borrowUnit': '借款期限',
  'page.collection.detail.loanAmount': '借款金额',
  'page.collection.detail.interestRate': '期利率',
  'page.collection.detail.totalShouldPayAmount': '应还金额',
  'page.collection.detail.totalPaidAmount': '已还金额',
  'page.collection.detail.totalUnPayAmount': '待还金额',
  'page.collection.detail.restPrincipal': '待还本金',
  'page.collection.detail.restInterest': '待还利息',
  'page.collection.detail.restPenalty': '待还罚息',
  'page.collection.detail.periods': '期数',
  'pages.collection.detail.loanHistory.createTime': '借款时间',
  'pages.collection.detail.loanHistory.repaymentDate': '还款日期',
  'pages.collection.detail.loanHistory.repaymentDateTime': '还款时间',
  'pages.collection.detail.effectAmount': '还款金额',
  'pages.collection.detail.interestPaid': '还款利息',
  'pages.collection.detail.penaltyPaid': '还款罚息',
  'pages.collection.detail.principalPaid': '还款本金',
  'pages.collection.my.detail.sendTime': '发送时间',
  'pages.collection.my.detail.smsSendPhoneType': '短信类别',
  'pages.collection.my.detail.triggerStatus': '触达状态',
  'pages.collection.my.detail.sendTo': '发送对象',
  'pages.configManage.queueManage.queue': '队列名',
  'pages.configManage.queueManage.groupName': '组名',
  'pages.operation.marketing.config.templateId': '模板id',
  'pages.operation.marketing.config.templateName': '模板名称',
  'page.collection.msgTemplate.sendType': '发送类型',
  'page.collection.msgTemplate.dailySendLimit': '日发送次数限制',
  'page.collection.msgTemplate.content': '内容',
  'pages.collection.my.detail.CollectionLog_CALL_RESULT': '电话催收日志',
  'pages.collection.my.detail.CollectionLog_VIBER_RESULT': 'Viber催收日志',
  'pages.collection.my.detail.CollectionLog_OTHER': '其他催收日志',
  'pages.collection.my.detail.MsgSendRecord': '短信催收日志',
  'page.collection.msgTemplate.contentTemplatePreview': '短信模板预览',
  'page.collection.msgTemplate.sendTimes': '发送次数',
  'page.collection.msgTemplate.contentTemplate': '内容模板',
  'page.collection.msgTemplate.insert': '插入模板占位符',
  'pages.system.privilege.user.employeeId': '员工ID',
  'pages.system.privilege.user.entityId': '实体ID',
  'pages.system.privilege.user.action': '操作',
  'pages.system.privilege.user.action.CollectionUser.delete': '离职（删除该员工）',
  'pages.system.privilege.user.action.CollectionUser.delete.confirm': '确认删除？',
  'pages.system.privilege.user.action.editPassword': '修改密码',
  'pages.system.privilege.user.action.managePermission': '管理权限',
  'pages.system.privilege.user.action.modifyUserRole': '修改用户角色',
  'pages.system.privilege.user.action.showUserRole': '查看用户角色',
  'pages.system.privilege.user.action.update': '更新',
  'pages.system.privilege.user.addPermissionForm.id': 'id',
  'pages.system.privilege.user.addPermissionForm.title': '管理权限',
  'pages.system.privilege.user.create': '创建用户',
  'pages.system.privilege.user.email': '邮箱',
  'pages.system.privilege.user.expandRow.action': '操作',
  'pages.system.privilege.user.expandRow.action.delete': '删除',
  'pages.system.privilege.user.expandRow.action.delete.confirm': '确认删除?',
  'pages.system.privilege.user.expandRow.id': 'id',
  'pages.system.privilege.user.expandRow.name': '名称',
  'pages.system.privilege.user.expandRow.remark': '备注',
  'pages.system.privilege.user.expandRow.type': '类型',
  'pages.system.privilege.user.groupId': '组id',
  'pages.system.privilege.user.id': 'ID',
  'pages.system.privilege.user.ModifyUserRoleForm.table.id': 'id',
  'pages.system.privilege.user.ModifyUserRoleForm.table.name': '角色名称',
  'pages.system.privilege.user.ModifyUserRoleForm.table.remark': '备注',
  'pages.system.privilege.user.ModifyUserRoleForm.title': '更新用户角色',
  'pages.system.privilege.user.phone': '账号',
  'pages.system.privilege.user.realName': '真实姓名',
  'pages.system.privilege.user.status': '状态',
  'pages.system.privilege.user.teamLeaderName': '组长名称',
  'pages.system.privilege.user.title': '用户管理',
  'pages.system.privilege.user.userForm.3cxCallerId': '3cx ID',
  'pages.system.privilege.user.userForm.email': '邮箱',
  'pages.system.privilege.user.userForm.groupId': '组名称',
  'pages.system.privilege.user.userForm.id': 'id',
  'pages.system.privilege.user.userForm.password': '密码',
  'pages.system.privilege.user.userForm.phone': '手机号',
  'pages.system.privilege.user.userForm.realName': '真实姓名',
  'pages.system.privilege.user.userForm.status': '状态',
  'pages.system.privilege.user.userForm.title.create': '创建用户',
  'pages.system.privilege.user.userForm.title.update': '更新用户',
  'pages.system.privilege.user.UserRoleModal.table.action': '操作',
  'pages.system.privilege.user.UserRoleModal.table.action.delete': '删除',
  'pages.system.privilege.user.UserRoleModal.table.action.delete.confirm': '确认删除?',
  'pages.system.privilege.user.UserRoleModal.table.id': 'id',
  'pages.system.privilege.user.UserRoleModal.table.name': '角色名称',
  'pages.system.privilege.user.UserRoleModal.table.remark': '备注',
  'pages.system.privilege.user.UserRoleModal.title': '用户角色信息',
  'pages.system.privilege.group.groupID': '组 ID',
  'pages.system.privilege.group.department': '部门',
  'pages.system.privilege.group.type': '类型',
  'pages.system.privilege.group.groupName': '组名',
  'pages.system.privilege.group.parentId': '父ID',
  'pages.system.privilege.group.parentName': '父名',
  'pages.system.privilege.group.description': '描述',
  'pages.system.privilege.group.expandedRow.adminId': '管理员 ID',
  'pages.system.privilege.group.expandedRow.adminStatus': '管理员状态',
  'pages.system.privilege.group.expandedRow.fullName': '全名',
  'pages.system.privilege.group.expandedRow.groupAdminUpdate': '组管理员更新',
  'pages.system.privilege.group.expandedRow.groupId': '组 ID',
  'pages.system.privilege.group.expandedRow.levelWeight': '权重',
  'pages.system.privilege.group.expandedRow.manualAverageCase': '手动均分案件',
  'pages.system.privilege.group.expandedRow.manualAverageCase.confirm': '确认手动均分案件？',
  'pages.system.privilege.group.expandedRow.manualResetCase': '手动释放案件',
  'pages.system.privilege.group.expandedRow.manualResetCase.confirm': '确认手动释放案件？',
  'pages.system.privilege.group.expandedRow.mobile': '手机号',
  'pages.system.privilege.group.expandedRow.status': '状态',
  'pages.system.privilege.group.expandedRow.updateTime': '更新时间',
  'pages.system.privilege.group.expandedRow.weight': '分案权重',
  'pages.collection.my.detail.NumberType': '发送对象',
  'pages.collection.my.detail.assignStatus': '分案状态',
  'pages.collection.my.detail.assignRecord': '分案记录',
  'pages.collection.my.detail.assignRecorder': '分案人',
  'pages.riskManage.blacklist.libraryType': '库类型',
  'pages.riskManage.blacklist.valueType': '值类型',
  'pages.riskManage.blacklist.value': '值',
  'pages.riskManage.blacklist.addReasons': '添加理由',
  'pages.riskManage.blacklist.addBlackList': '添加黑名单',
  'pages.riskManage.blacklist.editBlachList': '编辑黑名单',
  'common.import': 'Excel 导入',
  'common.add': '添加',
  'pages.riskManage.blacklist.addType': '添加类型',
  'pages.riskManage.blacklist.disabled': '禁用',
  'pages.riskManage.blacklist.enable': '启用',
  'pages.riskManage.blacklist.downloadExcel': '点击下载Excel模板',
  'common.uploadFile': '上传文件',
  'pages.collection.contactMobiles.caseType': '案件类型',
  'pages.collection.contactMobiles.customerName': '借款人姓名',
  'pages.collection.contactMobiles.DPD': '案件当前逾期天数',
  'pages.collection.contactMobiles.mobile': '联系人手机号',
  'pages.collection.contactMobiles.name': '联系人姓名',
  'pages.collection.contactMobiles.relationship': '关系',
  'common.viewDetail': '详情',
  'common.selectedAll': '全选',
  'pages.collection.workSchedule.queue': '队列',
  'pages.collection.workSchedule.teamLeaderId': '组长ID',
  'pages.collection.workSchedule.teamLeaderName': '组长姓名',
  'pages.collection.workSchedule.reviewerId': '催收员ID',
  'pages.collection.workSchedule.reviewerName': '催收员姓名',
  'pages.collection.changeLog.operator': '操作人',
  'pages.collection.changeLog.type': '操作类型',
  'pages.collection.changeLog.changeLog': '更新日志',
  'pages.collection.blacklist.addContent': '拉黑内容',
  'pages.collection.blacklist.libType': '库类型',
  'pages.collection.blacklist.blacklistTypeList': '拉黑内容',
  'pages.collection.blacklist.blacklistLibType': '库类型',
  'pages.collection.blacklist.comment': '备注',
  'pages.collection.blacklist.createBlacklist': '创建',
  'pages.collection.blacklist.createTime': '创建时间',
  'pages.collection.blacklist.customerId': '用户id',
  'pages.collection.blacklist.editBlacklist': '编辑',
  'pages.collection.blacklist.fileType': '文件类型',
  'pages.collection.blacklist.mobile': '手机号',
  'pages.collection.blacklist.operation': '操作',
  'pages.collection.blacklist.status': '状态',
  'pages.collection.blacklist.tip': '请选则表格行',
  'pages.collection.blacklist.updateTime': '更新时间',
  'pages.creditReview.creditRecordDetail.approveReason': '通过理由',
  'pages.creditReview.FirstReview.auditedRevie.detailCause': '详细理由',
  'pages.creditReview.FirstReview.auditedRevie.manualReviewReason': '人工审核理由',
  'pages.creditReview.FirstReview.auditedRevie.manualReviewDetailReason': '人工审核详情理由',
  'pages.creditReview.withdrawalReview.auditReview.withdrawalID': '提现ID',
  'pages.creditReview.withdrawalReview.auditReview.withdrawalStatus': '提现状态',
  'pages.creditReview.withdrawalReview.auditReview.withdrawalAmount': '提现金额',
  'common.lastOperator': '最后操作人',
  'pages.collection.outCallsManage.providerConfig.providerName': '供应商名称',
  'pages.collection.outCallsManage.providerConfig.apiConfigBtn': 'API配置',
  'pages.collection.outCallsManage.taskConfig.taskName': '外呼任务名称',
  'pages.collection.outCallsManage.taskConfig.providerName': '供应商',
  'pages.collection.outCallsManage.taskConfig.strategyId': '策略ID',
  'pages.collection.outCallsManage.taskConfig.loopMode': '循环模式',
  'pages.collection.outCallsManage.taskConfig.loopPushTime': '循环推送时间',
  'pages.collection.outCallsManage.taskConfig.taskType': '任务类型',
  'pages.collection.outCallsManage.taskAssociation.associatedTask': '选择外呼任务',
  'common.effectiveDate': '生效日期',
  'common.endDate': '终止日期',
  'pages.collection.outCallsManage.taskAssociation.associatedTime': '关联时间',
  'pages.collection.outCallsManage.taskAssociation.addBtn': '关联外呼任务',
  'pages.collection.outCallsManage.taskAssociation.cancelAssociate': '取消关联外呼任务',
  'pages.collection.outCallsManage.taskNameInputTip':
    '最多12个字符，不得重复：仅支持英文、数字、下划线',
  'pages.collection.outCallsManage.taskAssociation.cancelAssociate.tips':
    '确认取消所选择的关联任务？',
  'pages.collection.outCallsManage.rePush': '重新推送',
  'pages.collection.outCallsManage.rePushAi': '重新推送AI外呼',
  'pages.collection.outCallsManage.rePushModal': '确定重新推送？',
  'pages.collection.outCallsManage.batchId': '批次id',
  'pages.collection.outCallsRecords.recordName': '名单姓名',
  'pages.collection.outCallsRecords.recordPhone': '名单手机号',
  'pages.collection.outCallsManage.providerConfig.batchLimit': '批次名单上限',
  'pages.collection.outCallsManage.providerConfig.thirdVarName': '三方变量名称',
  'pages.collection.outCallsManage.providerConfig.systemVarParams': '系统变量传参',
  'pages.collection.outCallsManage.taskPush.pushTaskName': '推送任务名称',
  'pages.collection.outCallsManage.taskPush.pushStatus': '推送状态',
  'common.customerId': '用户ID',
  'common.taskName': '任务名称',
  'common.taskId': '任务ID',
  'common.remark': '备注',
  'pages.collection.outCallsManage.callMark': '呼叫标识',
  'pages.operation.outCallsRecords.answerTime': '接通时间',
  'pages.collection.outCallsRecords.callStatus': '呼叫状态',
  'pages.operation.marketing.outboundCallRecord.talkDuration': '通话时长',
  'pages.collection.my.detail.resultCode': '结论编码',
  'pages.collection.my.detail.resultDesc': '结论描述',
  'pages.creditReview.creditRecordDetail.btn.supplement': '补件',
  'pages.creditReview.creditRecordDetail.supplementReason': '补件理由',
  'pages.collection.my.bluOutCallTaskStrategyId': 'blu外呼策略id',
  'pages.collection.my.bluOutCallTaskName': 'blu外呼任务名称',
  'pages.creditReview.creditRecordDetail.forntPhotos': '证件正面照片',
  'pages.creditReview.creditRecordDetail.livingPhotos': '授信活体照片',
  'pages.creditReview.creditRecordDetail.livingVideo': '授信活体视频',
  'pages.creditReview.creditRecordDetail.withdrawalPhotos': '提现活体照片',
  'pages.creditReview.creditRecordDetail.withdrawalVideo': '提现活体视频',
  'pages.creditReview.creditRecordDetail.LivingSimilarityScore': '两次活体相似分',
  'pages.creditReview.creditRecordDetail.reversePhotos': '证件反面照片',
  'common.frozen': '冻结',
  'common.thaw': '解冻',
  'pages.creditReview.creditRecordManager.lockTip':
    '如果锁定则无法提现是否确认操作？确认则执行冻结操作将客户状态调整为LOCK',
  'pages.creditReview.creditRecordManager.thawTip': '解冻该用户则可正常提现，是否确认操作?',
  'pages.userManage.repaymentCode': '还款码',
  'pages.collection.monitor.stockCaseNum': '在库案件量',
  'pages.collection.monitor.stockCaseNewAllocat': '新分配案件量',
  'pages.collection.monitor.stockCaseClick': '点击式外呼回调案件量',
  'pages.collection.monitor.stockCasePredictive': '预测式外呼回调案件量',
  'pages.collection.monitor.stockCaseReminder': '催记更新案件量',
  'pages.collection.monitor.totalHomeworkCase': '总作业案件量',
  'pages.collection.monitor.caseAssignmentRate': '案件作业率',
  'pages.collection.monitor.casesWithoutReminders': '未出催案件拨打覆盖率',
  'pages.collection.monitor.callAverageNum': '全部案件平均拨打次数',
  'pages.collection.monitor.callCaseNum': '全部已拨打案件数',
  'pages.collection.monitor.callCoverRate': '全部案件拨打覆盖率',
  'pages.collection.monitor.callDimension': '接通维度',
  'pages.collection.monitor.callNum': '全部案件拨打次数',
  'pages.collection.monitor.contactNum': '接通数',
  'pages.collection.monitor.contactRate': '接通率',
  'pages.collection.monitor.count': '案件数量',
  'pages.collection.monitor.date': '日期',
  'pages.collection.monitor.finishDimension': '结清维度',
  'pages.collection.monitor.groupId': '组Id',
  'pages.collection.monitor.groupName': '组名称',
  'pages.collection.monitor.notUrgeCallAverageNum': '未出催案件拨打排名',
  'pages.collection.monitor.notUrgeCallCaseNum': '未出催已拨打案件数',
  'pages.collection.monitor.notUrgeCallCoverRate': '未出催案件拨打覆盖率',
  'pages.collection.monitor.notUrgeCallNum': '未出催案件拨打次数',
  'pages.collection.monitor.notUrgeCount': '未出催在库案件',
  'pages.collection.monitor.notUrgeRank': '未出催案件拨打排名',
  'pages.collection.monitor.principalAssignAmount': '本金分案金额',
  'pages.collection.monitor.principalRank': '本金催回排名',
  'pages.collection.monitor.principalRecallAmount': '本金催回金额',
  'pages.collection.monitor.principalRecallRate': '本金催回率',
  'pages.collection.monitor.product': '产品',
  'pages.collection.monitor.rank': '全部案件排名',
  'pages.collection.monitor.recoveryRate.day': '日',
  'pages.collection.monitor.recoveryRate.month': '月',
  'pages.collection.monitor.recoveryRate.week': '周',
  'pages.collection.monitor.reviewerId': '催收员Id',
  'pages.collection.monitor.reviewerName': '催收员名称',
  'pages.collection.monitor.totalAssignAmount': '总分案金额',
  'pages.collection.monitor.totalRank': '总金额催回排名',
  'pages.collection.monitor.totalRecallAmount': '总金额催回金额',
  'pages.collection.monitor.totalRecallRate': '总金额催回率',
  'pages.collection.monitor.urgeCallAverageNum': '出催案件平均拨打次数',
  'pages.collection.monitor.urgeCallCaseNum': '出催已拨打案件数',
  'pages.collection.monitor.urgeCallCoverRate': '出催案件拨打覆盖率',
  'pages.collection.monitor.urgeCallNum': '出催案件拨打次数',
  'pages.collection.monitor.urgeCount': '出催在库案件',
  'pages.collection.monitor.urgeRank': '出催案件拨打排名',
  'pages.collection.monitor.recoveryRate.hour': '小时',
  'pages.collection.specialDivision.assignBeforeCount': '分配前在库案件数',
  'pages.collection.specialDivision.assignLaterCount': '分配后在库案件数',
  'pages.collection.specialDivision.confirmDivision': '确认分案',
  'pages.collection.specialDivision.confirmDivision.confirm': '确认分案？',
  'pages.collection.specialDivision.groupId': '组id',
  'pages.collection.specialDivision.groupName': '组名称',
  'pages.collection.specialDivision.leaveDivision': '请假分案',
  'pages.collection.specialDivision.manualDivision': '手动分案',
  'pages.collection.specialDivision.num': '在库案件数',
  'pages.collection.specialDivision.preview': '分案结果预览',
  'pages.collection.specialDivision.resignationDivision': '离职分案',
  'pages.collection.specialDivision.reviewer': '催收员',
  'pages.collection.specialDivision.reviewerId': '催收员ID',
  'pages.collection.specialDivision.reviewerName': '催收员姓名',
  'pages.collection.specialDivision.searchLabel': '选择组或者催收员',
  'pages.collection.specialDivision.to': '分配至',
  'pages.collection.specialDivision.trigger': '触发器',
  'pages.collection.specialDivision.warning': '被分配人员状态需要为inactive',
  'pages.collection.divisionRecor.createTime': '分案时间',
  'pages.collection.divisionRecord.caseId': '案件Id',
  'pages.collection.divisionRecord.customerId': '用户ID',
  'pages.collection.divisionRecord.customerType': '用户类型',
  'pages.collection.divisionRecord.id': '日志Id',
  'pages.collection.divisionRecord.operatorId': '操作人ID',
  'pages.collection.divisionRecord.operatorName': '操作人',
  'pages.collection.divisionRecord.poolId': '分案Id',
  'pages.collection.divisionRecord.reviewerId': '催收员ID',
  'pages.collection.divisionRecord.reviewerName': '催收员',
  'pages.collection.divisionRecord.status': '状态',
  'pages.collection.divisionRecord.teamLeaderName': '组长',
  'pages.collection.divisionRecord.topic': '分案类型',
  'pages.collection.specialDivision.lastLogReminderTime': '最后更新催记时间',
  'pages.collection.specialDivision.scopeCase': '案件范围',
  'pages.collection.divisionRecord.operationTime': '操作时间',
  'common.querylimit.tip': '仅支持查询近一周全部数据或单个用户的数据',
  'pages.coolection.divisionRecord.topicEnum.absenceAssignCaseToReviewer': '请假分案',
  'pages.coolection.divisionRecord.topicEnum.dailyAssignCaseToReviewer': '日分案',
  'pages.coolection.divisionRecord.topicEnum.manualAssignCaseToReviewer': '手动分案',
  'pages.coolection.divisionRecord.topicEnum.monthAssignCaseToReviewer': '月分案',
  'pages.coolection.divisionRecord.topicEnum.resignationAssignCaseToReviewer': '离职分案',
  'pages.collection.specialDivision.selectErrorTip': '请选择的队列一样的案件',
  'pages.creditReview.antiFraud.source': '来源',
  'pages.creditReview.antiFraud.reason': '理由',
  'pages.creditReview.antiFraud.storageTime': '入库时间',
  'pages.creditReview.antiFraud.processingPersonnel': '处理人',
  'pages.creditReview.antiFraud.processingTime': '处理时间',
  'pages.creditReview.antiFraud.caseResult': '案调结果',
  'pages.creditReview.antiFraud.tab.pushCase': '推送案件',
  'pages.creditReview.antiFraud.tab.allCustomer': '全部客户',
  'pages.creditReview.antiFraud.loanAccount': '最近的借款账号',
  'pages.creditReview.antiFraud.equipNumber': '设备号',
  'pages.creditReview.antiFraud.emergencyNumber': '紧急联系人号码',
  'pages.creditReview.antiFraud.detail.loanAccount': '借款账号',
  'pages.creditReview.antiFraud.detail.loanAccountChannel': '借款账号渠道',
  'pages.creditReview.antiFraud.detail.orderNumber': '订单数量',
  'pages.creditReview.antiFraud.detail.overdueOrderNumber': '逾期订单数量',
  'pages.creditReview.antiFraud.detail.repaymentNum': '还款次数',
  'pages.collection.RemissionPlanConfig.completePlan': '全部结清方案',
  'pages.collection.RemissionPlanConfig.overdueRepaymentPlan': '逾期还款方案',
  'pages.remission.apply.OVERDUE_REMAIN_PRINCIPLE': '逾期待还本金',
  'pages.remission.apply.OVERDUE_REMAIN_INTEREST': '逾期待还利息',
  'pages.remission.apply.OVERDUE_REMAIN_PUNISHMENT': '逾期待还罚息',
  'pages.remission.apply.ALL_SETTLEMENT_TOTAL_REMAIN_PRINCIPLE': '全部结清总待还本金',
  'pages.remission.apply.ALL_SETTLEMENT_TOTAL_REMAIN_INTEREST': '全部结清总待还利息',
  'pages.remission.apply.ALL_SETTLEMENT_TOTAL_REMAIN_PUNISHMENT': '全部结清总待还罚息',
  'pages.remission.item': '减免科目',
  'pages.remission.maxRate': '最大减免比例',
  'pages.configManage.queueManage.reductionLimit': '催员每日减免限额',
  'pages.collection.my.detail.RemissionApply': '减免申请',
  'pages.collection.my.detail.btn.commonRemission': '常规减免申请',
  'pages.collection.my.detail.btn.specialRemission': '特殊减免申请',
  'pages.approve.applyType': '申请类型',
  'pages.approve.currentStep': '当前审批环节',
  'common.expirationDate': '有效期',
  'pages.approve.currentApprover': '当前审批人',
  'pages.approve.status': '审批状态',
  'pages.remission.apply.expires': '申请有效天数',
  'pages.remission.apply.plan': '减免计划',
  'pages.remission.maxAmount': '最大减免金额',
  'pages.remission.thisRate': '本次减免比例',
  'pages.remission.thisAmount': '本次减免金额',
  'pages.collection.recharge.reduceReason': '减免理由',
  'common.cancel': '取消',
  'common.pending': '待处理',
  'common.resolved': '已处理',
  'common.mine': '我发起的',
  'common.all': '全部',
  'common.operation.nullify': '作废',
  'common.operation.changeApprover': '变更审批人',
  'pages.approve.allRepayReduceCaseName': '全部结清减免方案名称',
  'common.operation.approve': '审批',
  'common.downloadExcel': '下载Excel文件',
  'pages.collectionCN.my.detail.lineAmount': '总额度',
  'common.option.pass': '通过',
  'common.option.pass.confirm': '确认通过？',
  'common.option.reject': '拒绝',
  'common.option.reject.confirm': '确认拒绝？',
  'common.option.retry.confirm': '确认重试？',
  'common.option.success': '操作成功',
  'pages.remission.apply.cancelTip': '确定作废该条减免申请？',
  'pages.finance.RefundManagement.approver': '审批人',
  'common.undo': '撤回',
  'pages.creditReview.antiFraud.updateToAntiFraud': '同步到案调系统',
  'pages.creditReview.antiFraud.importCaseReason': '导入案调原因',
  'common.result': '结果',
  'pages.collection.routerTest.btn.call': '呼叫',
  'pages.collection.routerTest.callTime': '外呼时间',
  'pages.collection.routerTest.callNumber': '外呼号码',
  'pages.collection.routerTest.callChannel': '外呼渠道',
  'pages.creditReview.FirstReview.manualAuditStatistics.supplementCasesNum': '补件案件数',
  'pages.creditReview.FirstReview.manualAuditStatistics.supplementRate': '补件率',
  'pages.creditReview.withdrawalReview.withdrawalStatistics.auditCloseNumber': '审核关闭数',
  'pages.creditReview.antiFraud.loanChannel': '借款渠道',
  'pages.creditReview.withdrawalReview.auditReview.withdrawalChannel': '提现渠道',
  'pages.creditReview.withdrawalReview.auditReview.withdrawalAccount': '提现账号',
  'pages.creditReview.changeOrderStatus.closeOrderNum': '关闭订单数',
  'pages.creditReview.changeOrderStatus.closeBeforeStatus': '关闭前状态',
  'pages.creditReview.changeOrderStatus.closeTime': '关闭时间',
  'pages.creditReview.changeOrderStatus.downloadExcel': '下载EXCEL模板',
  'pages.creditReview.changeOrderStatus.closeOrder': '关闭订单',
  'common.config.name': '配置名称',
  'common.showRecord': '查看记录',
  'pages.system.ABTestConfig.experimentName': '实验名称',
  'pages.system.ABTestConfig.experimentId': '实验ID',
  'pages.system.ABTestConfig.experimentStatus': '实验状态',
  'pages.system.ABTestConfig.recyclingStatus': '回收状态',
  'pages.system.ABTestConfig.rcyclingValue': '回收价值',
  'common.saveConfig': '保存配置',
  'pages.system.ABTestConfig.newExperimentalGroup': '新建实验组',
  'pages.system.ABTestConfig.save': '保存',
  'pages.system.ABTestConfig.experimentValue': '实验值',
  'pages.system.ABTestConfig.flow': '流量',
  'pages.system.ABTestConfig.recyclingStatus.tip': '回收状态开启后，实验用户走回收实验值对应的策略',
  'common.userGroupID': '人群code',
  'common.userGroupType': '人群类型',
  'common.type': '类型',
  'pages.marketing.parentStrategyCode': '父策略code',
  'pages.marketing.childrenStrategyCode': '子策略code',
  'pages.marketing.triggerType': '触达方式',
  'pages.marketing.grayInfo': '灰度信息',
  'pages.marketing.isGray': '是否灰度',
  'pages.marketing.marketingStrategyManage.addTrigger': '添加触达方式',
  'pages.marketing.marketingStrategyManage.addAiTrigger': '添加AI触达方式',
  'pages.marketing.marketingStrategyManage.triggerType': '策略类型',
  'pages.marketing.marketingStrategyManage.sendTypes': '触达方式',
  'pages.marketing.marketingStrategyManage.crowdTaskId': '人群任务id',
  'pages.marketing.marketingStrategyManage.triggerTime': '触达时点',
  'pages.marketing.marketingStrategyManage.triggerUserGroup': '触达人群',
  'pages.marketing.userGroupCreateTime': '客群生成时间',
  'pages.marketing.failedReason': '任务失败原因',
  'pages.marketing.strategyVirtualCount': '策略命中人数',
  'pages.marketing.strategyTriggerCount': '实际触达人数',
  'pages.marketing.couponUsedCount': '券使用人数',
  'pages.marketing.customerUsedCount': '券消费数量',
  'pages.marketing.category.userGroupCode': '人群code',
  'pages.marketing.category.userGroupName': '人群名称',
  'pages.marketing.category.userGroupType': '人群类型',
  'pages.marketing.category.generationMethod': '生成方式',
  'pages.marketing.category.updateMechanism': '刷新机制',
  'pages.marketing.category.isPostponed': '是否顺延',
  'pages.marketing.category.userGroupUpdateTime': '人群更新时间',
  'pages.marketing.category.btn.export': '系统导出',
  'pages.marketing.category.btn.uploadUsergroup': '上传人群',
  'pages.marketing.category.btn.markingRules': '打标规则',
  'pages.operation.marketing.crowdMonitor.populationQuantity': '人群生成数量',
  'pages.marketing.taskCreateTime': '任务发起时间',
  'pages.operation.marketing.aiNotificationRecord.taskStatus': '任务状态',
  'pages.operation.marketing.crowdDetails.riskControlMarking': '风控打标',
  'pages.operation.marketing.crowdDetails': '人群明细',
  'pages.marketing.marketingStrategyManage.tab.baseInfo': '基本信息',
  'pages.marketing.marketingStrategyManage.tab.ruleOfConfig': '客群筛选',
  'pages.marketing.marketingStrategyManage.tab.exeStrategy': '触达方式',
  'pages.marketing.marketingstrategy.testUsers': '测试用户ID（以,分隔最多提供5个）',
  'pages.marketing.childrenStrategyCode.generateTip': '（依据客群筛选自动生成）',
  'pages.marketing.marketingStrategyManage.calcRelationShip': '计算关系',
  'pages.marketing.marketingStrategyManage.ruleName': '规则名称',
  'pages.marketing.marketingStrategyManage.relationShip': '关系',
  'pages.marketing.marketingStrategyManage.ruleValue': '规则值',
  'pages.marketing.marketingStrategyManage.filterDesc': '筛选说明',
  'common.lastOperationTime': '最近一次操作时间',
  'pages.marketing.marketingStrategyManage.closetip': '关闭所有编辑内容将不会保存',
  'pages.coupon.config.couponFilter': '券过滤条件',
  'pages.marketing.deleteCurrent': '清空当前',
  'pages.marketing.deleteCurrentLabel':
    '当前已选策略已配置的灰度指标名称:(选中即保存时副除该灰度指标配置的所有规则)',
  'pages.operation.marketing.filterCondition': '过滤条件',
  'pages.marketing.marketingStrategyManage.addNewItem': '新增后续触达',
  'pages.marketing.category.btn.copy': '新增备用方式',
  'pages.operation.marketing.config.editable.title': '标题',
  'pages.operation.marketing.notification.parentNode.editable.sendTimeConfig': '任务执行时间',
  'pages.operation.marketing.notification.parentNode.editable.effectiveDateType': '生效日期',
  'pages.operation.marketing.config.editable.messageTypeEnum': '消息类型',
  'pages.operation.marketing.config.editable.channel': '渠道',
  'pages.operation.marketing.config.editable.content': '通知内容',
  'pages.operation.marketing.config.editable.backupChannel': '备用渠道',
  'pages.operation.marketing.template.name': '模板名称',
  'pages.noticeAiEditTable.carrierRule': '运营商过滤规则(非必填)',
  'pages.operation.marketing.notification.parentNode.editable.content': '内容',
  'pages.collection.strategyCode': '策略码',
  'pages.collection.strategyName': '策略名称',
  'pages.collection.relativeGroup': '关联组',
  'common.enable': '开启',
  'common.reset': '重置',
  'common.save': '保存',
  'common.submit': '提交',
  'page.marketing.marketingStrategyManage.action.dataMonitor': '数据监控',
  'common.copy': '复制',
  'common.operationPop.tip': '确认该操作？',
  'pages.marketing.category.delete.tip': '当前人群已关联策略是否删除？',
  'pages.marketing.categoryManage.execImmediateTip': '将立即发起触达，是否确认?',
  'pages.marketing.category.switchstatus.tip': '当前人群已有激活状态的策略不允许切换状态',
  'pages.marketing.categoryManage.execImmidiate': '立即执行',
  'pages.marketing.category.userGroupAmount': '最近一次生成人群数量',
  'pages.operation.marketing.crowdMonitor.crowdInfo': '人群信息',
  'common.viewDeatils': '查看明细',
  'common.downloadDetails': '下载明细',
  'pages.marketing.crowdMonitor.manualGroup.btn': '生成手动人群',
  'common.download': '下载',
  'pages.operation.snapshot': '快照',
  'common.dateRange': '日期区间',
  'pages.operation.marketing.record.ChildrenStrategyCode': '子策略名称',
  'pages.operation.marketing.notification.record.messageType': '消息类型',
  'common.channel': '渠道',
  'common.sendTo': '发送至',
  'pages.operation.marketing.notification.record.sendTime': '发送时间',
  'pages.operation.marketing.notification.record.content': '发送内容',
  'pages.review.riskControlCard.channel': '渠道',
  'page.customer.contactRecord.sendTime': '发送时间',
  'pages.operation.marketing.sendResult': '发送结果',
  'pages.operation.marketing.result': '结果信息',
  'pages.operation.marketing.notification.record.sendTo': '发送至',
  'pages.marketing.marketingMonitor.filterCount': '过滤人数',
  'pages.marketing.marketingMonitor.failedSendCount': '发送失败人数',
  'pages.marketing.marketingMonitor.strategyFilterCount': '被过滤',
  'pages.marketing.marketingMonitor.callThirdPartyFailedCount': '调用三方失败',
  'pages.marketing.marketingMonitor.thirdPartyDealFailedCount': '三方失败',
  'common.notSaveTip': '确定该操作？该操作编辑内容将不会保存',
  'pages.operation.repayrouteConfig.cardTitle': '电子钱包路由配置',
  'pages.operation.repayrouteConfig.xendit': 'XENDIT支持电子钱包',
  'pages.operation.repayrouteConfig.skypay': 'SKYPAY支持电子钱包',
  'pages.operation.repayrouteConfig.netbank': 'NETBANK支持电子钱包',
  'pages.operation.repayrouteConfig.amount': '还款金额阈值',
  'common.userCategory': '用户标签',
  'pages.operation.repayrouteConfig.ltAmount': '金额小于阈值',
  'pages.operation.repayrouteConfig.geAmount': '金额大于等于阈值',
  'pages.technology.testRepayment.createTest': '创建测试用户',
  'pages.technology.testRepayment.updateTest': '编辑测试用户',
  'pages.technology.testRepayment.recharge': '充值',
  'pages.technology.testRepayment.rechargeAmount': '充值金额',
  'pages.technology.testRepayment.rechargeType': '充值类型',
  'pages.userManage.userFeedback.platform': '平台',
};
