import type { FormInstance } from 'antd';
import { useRef } from 'react';
import { useIntl } from 'umi';
import FormattedMessage from '../FormattedMessage/FormattedMessage';
import FormModal from '../FormModal/FormModal';

type UpdatePasswordParams = { password: string; newpassword: string };

export default ({
  visible,
  setVisible,
  onFinish,
}: {
  visible: boolean;
  setVisible: (v: boolean) => void;
  onFinish?: (password: string) => Promise<any> | void;
}) => {
  const formRef = useRef<FormInstance<UpdatePasswordParams>>();
  const { formatMessage } = useIntl();

  return !visible ? (
    <></>
  ) : (
    <FormModal<UpdatePasswordParams>
      title={<FormattedMessage id="pages.headerDropdown.updatePassword.updatePassword" />}
      visible={visible}
      formRef={formRef}
      onCancel={() => setVisible(false)}
      onFinish={async (from) => {
        setVisible(false);
        await onFinish?.(from.password);
      }}
      fieldProps={[
        {
          name: 'password',
          label: <FormattedMessage id="pages.headerDropdown.updatePassword.password" />,
          rules: [{ required: true, message: 'Please input Password' }],
          feildType: 'password',
        },
        {
          name: 'newpassword',
          label: <FormattedMessage id="pages.headerDropdown.updatePassword.confirmPassword" />,
          feildType: 'password',
          rules: [
            { required: true, message: 'Please re-enter password' },
            {
              validator(_rule, _val, callback) {
                const fields = formRef.current?.getFieldsValue() as UpdatePasswordParams;
                if (fields.newpassword !== fields.password) {
                  callback(
                    formatMessage({ id: 'pages.headerDropdown.updatePassword.notMatchMsg' }),
                  );
                  return;
                }
                callback();
              },
            },
          ],
        },
      ]}
    />
  );
};
