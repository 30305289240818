// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import CrownOutlined from '@ant-design/icons/CrownOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import TagsOutlined from '@ant-design/icons/TagsOutlined';
import PayCircleOutlined from '@ant-design/icons/PayCircleOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import MoneyCollectOutlined from '@ant-design/icons/MoneyCollectOutlined';
import PropertySafetyOutlined from '@ant-design/icons/PropertySafetyOutlined'

export default {
  SmileOutlined,
CrownOutlined,
UserOutlined,
TagsOutlined,
PayCircleOutlined,
PhoneOutlined,
MoneyCollectOutlined,
PropertySafetyOutlined
}
    