/**
 * Created by cleverdou on 2022/4/29.
 */
// @ts-nocheck
import React, { useState } from 'react';
import { Menu, Dropdown, Modal } from 'antd';
import type { ClickParam } from 'antd/es/menu';
import type { DropDownProps } from 'antd/es/dropdown';
import { changeEnv, ENV, getEnv, isProd } from '@/utils/changeEnv';

export interface HeaderDropdownProps extends DropDownProps {
  overlayClassName?: string;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter';
}

const HeaderDropdown: React.FC<HeaderDropdownProps> = ({ overlayClassName: cls, ...restProps }) => (
  <Dropdown overlayClassName={cls} {...restProps} />
);

interface SelectLangProps {
  globalIconClassName?: string;
  postLocalesData?: (locales: LocalData[]) => LocalData[];
  className?: string;
  reload?: boolean;
  icon?: React.ReactNode;
}

export const SelectEnv: React.FC<SelectLangProps> = (props) => {
  const { globalIconClassName, postLocalesData, onItemClick, icon, style, reload, ...restProps } =
    props;
  const [selectedLang, setSelectedLang] = useState(() => getEnv());

  const changeLang = (key: string): void => {
    changeEnv(key, reload);
    setSelectedLang(getEnv());
  };

  const allLangUIConfig = ENV;
  const handleClick = ({ key }: ClickParam) => {
    if (isProd(key)) {
      Modal.confirm({
        type: 'warning',
        title: '这是生产环境',
        content: '请确认选择的环境是正确的',
        onOk: () => changeLang(key),
      });
    } else {
      changeLang(key);
    }
  };

  const menuItemStyle = { minWidth: '160px' };
  const langMenu = (
    <Menu selectedKeys={[selectedLang]} onClick={handleClick}>
      {allLangUIConfig.map((localeObj) => {
        return (
          <Menu.Item key={localeObj} style={menuItemStyle}>
            {localeObj}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const inlineStyle = {
    cursor: 'pointer',
    padding: '12px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    verticalAlign: 'middle',
    ...style,
  };

  return (
    <HeaderDropdown overlay={langMenu} placement="bottomRight" {...restProps}>
      <span className={globalIconClassName} style={inlineStyle}>
        {selectedLang}
      </span>
    </HeaderDropdown>
  );
};
