/**
 * create 新建
 * edit 编辑
 * disabled 禁用
 * delete 删除
 */
export default {
  id: 'developer.indicatorManagement.dataSourceManage',
  components: ['create', 'edit', 'disabled', 'delete'],
} as const;
