import React, { useCallback, useMemo, useState } from 'react';
import { LogoutOutlined, UserOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar, Menu, message, Spin } from 'antd';
import { history, useModel } from 'umi';

import { useIntl } from 'umi';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { outLogin, updatePassword } from '@/services/user/api';
import { toLogin } from '@/pages/user/Login';
import FormattedMessage from '../FormattedMessage/FormattedMessage';
import PasswordModal from '../PasswordModal/PasswordModal';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录，并且将当前的 url 保存
 */
export const loginOut = async (product: string) => {
  await outLogin({ data: { product } });
  toLogin(true);
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { formatMessage } = useIntl();
  const product = useMemo(() => {
    return initialState?.app || 'HC';
  }, [initialState?.app]);

  const onMenuClick: any = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === 'logout') {
        setInitialState((s: any) => ({ ...s, currentUser: undefined })).then();
        loginOut(product).then();
        history.push(`/`);
        return;
      }

      if (key === 'update-password') {
        setShowPasswordModal(true);
        return;
      }
      history.push(`/account/${key}`);
    },
    [product, setInitialState],
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.user || !currentUser.user.phone) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="update-password">
        <EditOutlined />
        <FormattedMessage id="pages.headerDropdown.updatePassword.updatePassword" />
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined />
        <FormattedMessage id="pages.headerDropdown.updatePassword.logout" />
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <PasswordModal
        visible={showPasswordModal}
        setVisible={setShowPasswordModal}
        onFinish={async (password) => {
          setShowPasswordModal(false);
          await updatePassword(password);
          message.success(formatMessage({ id: 'pages.headerDropdown.updatePassword.message' }));
          loginOut(product);
        }}
      />
      <HeaderDropdown overlay={menuHeaderDropdown}>
        <span className={`${styles.action} ${styles.account}`}>
          <Avatar
            size="small"
            className={styles.avatar}
            alt="avatar"
            shape="square"
            icon={<UserOutlined />}
          />

          <span className={`${styles.name} anticon`}>
            {currentUser.user.realName
              ? currentUser.user.realName
              : currentUser.user.employeeId
              ? currentUser.user.employeeId
              : currentUser.user.phone}
          </span>
        </span>
      </HeaderDropdown>
    </>
  );
};

export default AvatarDropdown;
