/**
 * create 新建
 * test 测试
 * edit 编辑
 * disabled 禁用
 * delete 删除
 */
export default {
  id: 'developer.indicatorManagement.indicationQuery',
  components: ['create', 'test', 'edit', 'disabled', 'delete'],
} as const;
