// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/codebuild/output/src4289009326/src/happycash-admin/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/codebuild/output/src4289009326/src/happycash-admin/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/user/login:-0-0-0-0"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-0-0-1"
              }
            ],
            "accessId": "/user:-0-0-0"
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
            "accessId": "undefined:-0-0-1"
          }
        ],
        "accessId": "/user:-0-0"
      },
      {
        "path": "/welcome",
        "name": "welcome",
        "icon": "smile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true,
        "accessId": "/welcome:-0-1"
      },
      {
        "path": "/system",
        "name": "system",
        "icon": "crown",
        "routes": [
          {
            "path": "/system",
            "redirect": "/system/privilege",
            "exact": true,
            "accessId": "/system:-0-2-0"
          },
          {
            "path": "/system/privilege",
            "name": "privilege",
            "routes": [
              {
                "path": "/system/privilege",
                "redirect": "/system/privilege/permission",
                "exact": true,
                "accessId": "/system/privilege:-0-2-1-0"
              },
              {
                "path": "/system/privilege/user",
                "name": "user",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__privilege__user' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/system/privilege/user'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/system/privilege/user:-0-2-1-1"
              },
              {
                "path": "/system/privilege/reviewUser",
                "name": "reviewUser",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__privilege__reviewUser' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/system/privilege/reviewUser'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/system/privilege/reviewUser:-0-2-1-2"
              },
              {
                "path": "/system/privilege/collection-user",
                "name": "collectionUser",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__privilege__collection-user' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/system/privilege/collection-user'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/system/privilege/collection-user:-0-2-1-3"
              },
              {
                "path": "/system/privilege/role",
                "name": "role",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__privilege__role' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/system/privilege/role'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/system/privilege/role:-0-2-1-4"
              },
              {
                "path": "/system/privilege/path",
                "name": "path",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__privilege__path' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/system/privilege/path'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/system/privilege/path:-0-2-1-5"
              },
              {
                "path": "/system/privilege/permission",
                "name": "permission",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__privilege__permission' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/system/privilege/permission'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/system/privilege/permission:-0-2-1-6"
              },
              {
                "path": "/system/privilege/group",
                "name": "group",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__privilege__group' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/system/privilege/group'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/system/privilege/group:-0-2-1-7"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-2-1-8"
              }
            ],
            "accessId": "/system/privilege:-0-2-1"
          },
          {
            "path": "/system/noticeManage",
            "name": "noticeManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__noticeManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/system/noticeManage'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/system/noticeManage:-0-2-2"
          },
          {
            "path": "/system/ABTestConfig",
            "name": "ABTestConfig",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__ABTestConfig' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/system/ABTestConfig'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/system/ABTestConfig:-0-2-3"
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
            "accessId": "undefined:-0-2-4"
          }
        ],
        "accessId": "/system:-0-2"
      },
      {
        "path": "/userManage",
        "name": "userManage",
        "icon": "UserOutlined",
        "routes": [
          {
            "path": "/userManage",
            "redirect": "/system/userFeedback",
            "exact": true,
            "accessId": "/userManage:-0-3-0"
          },
          {
            "path": "/userManage/userFeedback",
            "name": "userFeedback",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userManage__userFeedback' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/userManage/userFeedback'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/userManage/userFeedback:-0-3-1"
          },
          {
            "path": "/userManage/userList",
            "name": "userList",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userManage__userList' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/userManage/userList'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/userManage/userList:-0-3-2"
          },
          {
            "path": "/userManage/userListDetail",
            "name": "userListDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userManage__userListDetail' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/userManage/userListDetail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true,
            "accessId": "/userManage/userListDetail:-0-3-3"
          },
          {
            "path": "/userManage/repaymentCode",
            "name": "repaymentCode",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userManage__repaymentCode' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/userManage/repaymentCode'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/userManage/repaymentCode:-0-3-4"
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
            "accessId": "undefined:-0-3-5"
          }
        ],
        "accessId": "/userManage:-0-3"
      },
      {
        "path": "/operation",
        "name": "operation",
        "icon": "TagsOutlined",
        "routes": [
          {
            "path": "/operation/channelManage",
            "name": "channelManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__channelManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/operation/channelManage'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/operation/channelManage:-0-4-0"
          },
          {
            "path": "/operation/categoryManage",
            "name": "categoryManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__categoryManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/operation/categoryManage'), loading: LoadingComponent}),
            "hideWhenComplianceIsOn": true,
            "exact": true,
            "accessId": "/operation/categoryManage:-0-4-1"
          },
          {
            "path": "/operation/crowdMonitor",
            "name": "crowdMonitor",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__crowdMonitor' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/operation/crowdMonitor'), loading: LoadingComponent}),
            "hideWhenComplianceIsOn": true,
            "exact": true,
            "accessId": "/operation/crowdMonitor:-0-4-2"
          },
          {
            "path": "/operation/marketingStrategyManage",
            "name": "marketingStrategyManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__marketingStrategyManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/operation/marketingStrategyManage'), loading: LoadingComponent}),
            "hideWhenComplianceIsOn": true,
            "exact": true,
            "accessId": "/operation/marketingStrategyManage:-0-4-3"
          },
          {
            "path": "/operation/marketingMonitor",
            "name": "marketingMonitor",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__marketingMonitor' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/operation/marketingMonitor'), loading: LoadingComponent}),
            "hideWhenComplianceIsOn": true,
            "exact": true,
            "accessId": "/operation/marketingMonitor:-0-4-4"
          },
          {
            "path": "/operation/notificationRecord",
            "name": "notificationRecord",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__notificationRecord' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/operation/notificationRecord'), loading: LoadingComponent}),
            "hideWhenComplianceIsOn": true,
            "exact": true,
            "accessId": "/operation/notificationRecord:-0-4-5"
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
            "accessId": "undefined:-0-4-6"
          }
        ],
        "accessId": "/operation:-0-4"
      },
      {
        "path": "/collection",
        "name": "collection",
        "icon": "PayCircleOutlined",
        "app": "ap",
        "routes": [
          {
            "path": "/collection",
            "redirect": "/collection/my",
            "exact": true,
            "accessId": "/collection:-0-5-0"
          },
          {
            "path": "/collection/my",
            "name": "my",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__my' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/my'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/collection/my:-0-5-1"
          },
          {
            "path": "/collection/allCase",
            "name": "allCase",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__allCase' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/allCase'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/collection/allCase:-0-5-2"
          },
          {
            "path": "/collection/my/caseDetail/:customerId",
            "name": "caseDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__mydetail' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/mydetail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true,
            "accessId": "/collection/my/caseDetail/:customerId:-0-5-3"
          },
          {
            "path": "/collection/specialDivision",
            "name": "specialDivision",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__specialDivision' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/specialDivision'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/collection/specialDivision:-0-5-4"
          },
          {
            "path": "/collection/divisionRecord",
            "name": "divisionRecord",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__divisionRecord' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/divisionRecord'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/collection/divisionRecord:-0-5-5"
          },
          {
            "path": "/collection/config",
            "name": "CollectionConfig",
            "routes": [
              {
                "path": "/collection/config",
                "redirect": "/collection/config/queueManage",
                "exact": true,
                "accessId": "/collection/config:-0-5-6-0"
              },
              {
                "path": "/collection/config/remissionPlanConfig",
                "name": "RemissionPlanConfig",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__configManage__remissionPlanConfig' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/configManage/remissionPlanConfig'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/config/remissionPlanConfig:-0-5-6-1"
              },
              {
                "path": "/collection/config/queueManage",
                "name": "QueueManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__configManage__queueManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/configManage/queueManage'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/config/queueManage:-0-5-6-2"
              },
              {
                "path": "/collection/config/groupQueueManage",
                "name": "GroupQueueManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__configManage__groupQueueManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/configManage/groupQueueManage'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/config/groupQueueManage:-0-5-6-3"
              },
              {
                "path": "/collection/config/messageTemplate",
                "name": "MessageTemplate",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__configManage__messageTemplate' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/configManage/messageTemplate'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/config/messageTemplate:-0-5-6-4"
              },
              {
                "path": "/collection/config/sparkingDivisionRulesConfig",
                "name": "SparkingDivisionRulesConfig",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__configManage__sparkingDivisionRulesConfig' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/configManage/sparkingDivisionRulesConfig'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/config/sparkingDivisionRulesConfig:-0-5-6-5"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-5-6-6"
              }
            ],
            "accessId": "/collection/config:-0-5-6"
          },
          {
            "path": "/collection/outCallsManage",
            "icon": "PhoneOutlined",
            "name": "outCallsManage",
            "routes": [
              {
                "path": "/collection/outCallsManage/providerConfig",
                "name": "providerConfig",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__outCallsManage__providerConfig' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/outCallsManage/providerConfig'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/outCallsManage/providerConfig:-0-5-7-0"
              },
              {
                "path": "/collection/outCallsManage/taskConfig",
                "name": "taskConfig",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__outCallsManage__taskConfig' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/outCallsManage/taskConfig'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/outCallsManage/taskConfig:-0-5-7-1"
              },
              {
                "path": "/collection/outCallsManage/taskAssociation",
                "name": "taskAssociation",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__outCallsManage__taskAssociation' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/outCallsManage/taskAssociation'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/outCallsManage/taskAssociation:-0-5-7-2"
              },
              {
                "path": "/collection/outCallsManage/taskPush",
                "name": "taskPush",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__outCallsManage__taskPush' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/outCallsManage/taskPush'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/outCallsManage/taskPush:-0-5-7-3"
              },
              {
                "path": "/collection/outCallsManage/callList",
                "name": "callList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__outCallsManage__callList' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/outCallsManage/callList'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/outCallsManage/callList:-0-5-7-4"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-5-7-5"
              }
            ],
            "accessId": "/collection/outCallsManage:-0-5-7"
          },
          {
            "path": "/collection/outCallsRecords",
            "icon": "PhoneOutlined",
            "name": "outCallsRecords",
            "routes": [
              {
                "path": "/collection/outCallsRecords/BLURecords",
                "name": "BLURecords",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__outCallsRecords__BLURecords' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/outCallsRecords/BLURecords'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/outCallsRecords/BLURecords:-0-5-8-0"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-5-8-1"
              }
            ],
            "accessId": "/collection/outCallsRecords:-0-5-8"
          },
          {
            "path": "/collection/ApproveCenter",
            "name": "ApproveCenter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__ApproveCenter' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/ApproveCenter'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/collection/ApproveCenter:-0-5-9"
          },
          {
            "path": "/collection/workSchedule",
            "name": "workSchedule",
            "routes": [
              {
                "path": "/collection/workSchedule/detail",
                "name": "detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__workSchedule__detail' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/workSchedule/detail'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/workSchedule/detail:-0-5-10-0"
              },
              {
                "path": "/collection/workSchedule/changeLog",
                "name": "changeLog",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__workSchedule__changeLog' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/workSchedule/changeLog'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/workSchedule/changeLog:-0-5-10-1"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-5-10-2"
              }
            ],
            "accessId": "/collection/workSchedule:-0-5-10"
          },
          {
            "path": "/collection/mobiles",
            "name": "mobiles",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__mobiles' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/mobiles'), loading: LoadingComponent}),
            "hideWhenComplianceIsOn": true,
            "exact": true,
            "accessId": "/collection/mobiles:-0-5-11"
          },
          {
            "path": "/collection/contactMobiles",
            "name": "contactMobiles",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__contactMobiles' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/contactMobiles'), loading: LoadingComponent}),
            "hideWhenComplianceIsOn": true,
            "exact": true,
            "accessId": "/collection/contactMobiles:-0-5-12"
          },
          {
            "path": "/collection/blacklist",
            "name": "blacklist",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__blacklist' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/blacklist'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/collection/blacklist:-0-5-13"
          },
          {
            "path": "/collection/routerTest",
            "name": "routerTest",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__routerTest' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/routerTest'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/collection/routerTest:-0-5-14"
          },
          {
            "path": "/collection/monitor",
            "name": "monitor",
            "routes": [
              {
                "path": "/collection/monitor/urgencyRate",
                "name": "urgencyRate",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection__monitor__urgencyRate' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/collection/monitor/urgencyRate'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/collection/monitor/urgencyRate:-0-5-15-0"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-5-15-1"
              }
            ],
            "accessId": "/collection/monitor:-0-5-15"
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
            "accessId": "undefined:-0-5-16"
          }
        ],
        "accessId": "/collection:-0-5"
      },
      {
        "path": "/creditReview",
        "icon": "MoneyCollectOutlined",
        "name": "creditReview",
        "routes": [
          {
            "path": "/creditReview",
            "redirect": "/creditReview/FirstReview",
            "exact": true,
            "accessId": "/creditReview:-0-6-0"
          },
          {
            "path": "/creditReview/FirstReview",
            "name": "FirstReview",
            "routes": [
              {
                "path": "/creditReview/FirstReview",
                "redirect": "/creditReview/FirstReview/auditReview",
                "exact": true,
                "accessId": "/creditReview/FirstReview:-0-6-1-0"
              },
              {
                "path": "/creditReview/FirstReview/auditReview",
                "name": "auditReview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__FirstReview__auditReview' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/FirstReview/auditReview'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/creditReview/FirstReview/auditReview:-0-6-1-1"
              },
              {
                "path": "/creditReview/FirstReview/auditedReview",
                "name": "auditedReview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__FirstReview__auditedReview' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/FirstReview/auditedReview'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/creditReview/FirstReview/auditedReview:-0-6-1-2"
              },
              {
                "path": "/creditReview/FirstReview/manualAuditStatistics",
                "name": "manualAuditStatistics",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__FirstReview__manualAuditStatistics' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/FirstReview/manualAuditStatistics'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/creditReview/FirstReview/manualAuditStatistics:-0-6-1-3"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-6-1-4"
              }
            ],
            "accessId": "/creditReview/FirstReview:-0-6-1"
          },
          {
            "path": "/creditReview/withdrawalReview",
            "name": "withdrawalReview",
            "routes": [
              {
                "path": "/creditReview/withdrawalReview",
                "redirect": "/creditReview/withdrawalReview/auditReview",
                "exact": true,
                "accessId": "/creditReview/withdrawalReview:-0-6-2-0"
              },
              {
                "path": "/creditReview/withdrawalReview/auditReview",
                "name": "auditReview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__withdrawalReview__auditReview' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/withdrawalReview/auditReview'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/creditReview/withdrawalReview/auditReview:-0-6-2-1"
              },
              {
                "path": "/creditReview/withdrawalReview/auditedReview",
                "name": "auditedReview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__withdrawalReview__auditedReview' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/withdrawalReview/auditedReview'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/creditReview/withdrawalReview/auditedReview:-0-6-2-2"
              },
              {
                "path": "/creditReview/withdrawalReview/withdrawalDetail",
                "name": "withdrawalDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__withdrawalReview__withdrawalDetail' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/withdrawalReview/withdrawalDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true,
                "accessId": "/creditReview/withdrawalReview/withdrawalDetail:-0-6-2-3"
              },
              {
                "path": "/creditReview/withdrawalReview/withdrawalStatistics",
                "name": "withdrawalStatistics",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__withdrawalReview__withdrawalStatistics' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/withdrawalReview/withdrawalStatistics'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/creditReview/withdrawalReview/withdrawalStatistics:-0-6-2-4"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-6-2-5"
              }
            ],
            "accessId": "/creditReview/withdrawalReview:-0-6-2"
          },
          {
            "path": "/creditReview/creditRecordManager",
            "name": "creditRecordManager",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__creditRecordManager' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/creditRecordManager'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/creditReview/creditRecordManager:-0-6-3"
          },
          {
            "path": "/creditReview/creditRecordDetail",
            "name": "creditRecordDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__creditRecordDetail' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/creditRecordDetail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true,
            "accessId": "/creditReview/creditRecordDetail:-0-6-4"
          },
          {
            "path": "/creditReview/withdrawManage",
            "name": "withdrawManager",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__withdrawManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/withdrawManage'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/creditReview/withdrawManage:-0-6-5"
          },
          {
            "path": "/creditReview/changeOrderStatus",
            "name": "changeOrderStatus",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__changeOrderStatus' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/changeOrderStatus'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/creditReview/changeOrderStatus:-0-6-6"
          },
          {
            "path": "/creditReview/AntiFraud",
            "name": "AntiFraud",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__AntiFraud' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/AntiFraud'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/creditReview/AntiFraud:-0-6-7"
          },
          {
            "path": "/creditReview/AntiFraud/detail",
            "name": "AntiFraudDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__creditReview__AntiFraudDetail' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/creditReview/AntiFraudDetail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true,
            "accessId": "/creditReview/AntiFraud/detail:-0-6-8"
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
            "accessId": "undefined:-0-6-9"
          }
        ],
        "accessId": "/creditReview:-0-6"
      },
      {
        "path": "/developer",
        "name": "developer",
        "icon": "UserOutlined",
        "hideWhenComplianceIsOn": true,
        "routes": [
          {
            "path": "/developer",
            "redirect": "/developer/indicatorManagement/indicationManage",
            "exact": true,
            "accessId": "/developer:-0-7-0"
          },
          {
            "path": "/developer/indicatorManagement/",
            "name": "indicatorManagement",
            "routes": [
              {
                "path": "/developer/indicatorManagement/indicationManage",
                "name": "indicationManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developer__indicatorManagement__indicationManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/developer/indicatorManagement/indicationManage'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/developer/indicatorManagement/indicationManage:-0-7-1-0"
              },
              {
                "path": "/developer/indicatorManagement/modelManage",
                "name": "modelManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developer__indicatorManagement__modelManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/developer/indicatorManagement/modelManage'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/developer/indicatorManagement/modelManage:-0-7-1-1"
              },
              {
                "path": "/developer/indicatorManagement/datasourceManage",
                "name": "datasourceManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developer__indicatorManagement__datasourceManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/developer/indicatorManagement/datasourceManage'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/developer/indicatorManagement/datasourceManage:-0-7-1-2"
              },
              {
                "path": "/developer/indicatorManagement/scriptManage",
                "name": "scriptManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developer__indicatorManagement__scriptManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/developer/indicatorManagement/scriptManage'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/developer/indicatorManagement/scriptManage:-0-7-1-3"
              },
              {
                "path": "/developer/indicatorManagement/groupManage",
                "name": "groupManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developer__indicatorManagement__groupManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/developer/indicatorManagement/groupManage'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/developer/indicatorManagement/groupManage:-0-7-1-4"
              },
              {
                "path": "/developer/indicatorManagement/indicatorGroupManage",
                "name": "indicatorGroupManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developer__indicatorManagement__indicatorGroupManage' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/developer/indicatorManagement/indicatorGroupManage'), loading: LoadingComponent}),
                "exact": true,
                "accessId": "/developer/indicatorManagement/indicatorGroupManage:-0-7-1-5"
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
                "accessId": "undefined:-0-7-1-6"
              }
            ],
            "accessId": "/developer/indicatorManagement/:-0-7-1"
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
            "accessId": "undefined:-0-7-2"
          }
        ],
        "accessId": "/developer:-0-7"
      },
      {
        "path": "/loanManage",
        "icon": "MoneyCollectOutlined",
        "name": "loanManage",
        "routes": [
          {
            "path": "/loanManage",
            "redirect": "/loanManage/loanRecords",
            "exact": true,
            "accessId": "/loanManage:-0-8-0"
          },
          {
            "path": "/loanManage/loanRecords",
            "name": "loanRecords",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__loanManage__loanRecords' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/loanManage/loanRecords'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/loanManage/loanRecords:-0-8-1"
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
            "accessId": "undefined:-0-8-2"
          }
        ],
        "accessId": "/loanManage:-0-8"
      },
      {
        "path": "/riskManage",
        "icon": "PropertySafetyOutlined",
        "name": "riskManage",
        "routes": [
          {
            "path": "/riskManage",
            "redirect": "/riskManage/blacklist",
            "exact": true,
            "accessId": "/riskManage:-0-9-0"
          },
          {
            "path": "/riskManage/blacklist",
            "name": "blacklist",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__riskManage__blacklist' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/riskManage/blacklist'), loading: LoadingComponent}),
            "exact": true,
            "accessId": "/riskManage/blacklist:-0-9-1"
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
            "accessId": "undefined:-0-9-2"
          }
        ],
        "accessId": "/riskManage:-0-9"
      },
      {
        "path": "/index.html",
        "redirect": "/welcome",
        "exact": true,
        "accessId": "/index.html:-0-10"
      },
      {
        "path": "/",
        "redirect": "/welcome",
        "exact": true,
        "accessId": "/:-0-11"
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
        "exact": true,
        "accessId": "undefined:-0-12"
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/codebuild/output/src4289009326/src/happycash-admin/src/pages/404'), loading: LoadingComponent}),
        "accessId": "undefined:-0-13"
      }
    ],
    "accessId": "/:-0"
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
