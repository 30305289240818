/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
import type { IRoute } from 'umi';
import { RouteAccessMap } from '@@/plugin-generate-access/accessIds';

export default function access(initialState: { currentUser?: API.CurrentUser | undefined }) {
  const { currentUser } = initialState || {};
  const currentUserPermissions = new Set(currentUser?.permissions?.map((v) => v.name));
  return new Proxy(
    {
      canRouteAccess: (route: IRoute | { name: string }) => {
        const currentNeedPermissions: string[] = RouteAccessMap[route.name];
        if (!currentNeedPermissions) {
          return true;
        }
        // 和currentUser中的权限进行比较 返回是否可以访问
        for (let i = 0; i < currentNeedPermissions.length; i += 1) {
          if (currentUserPermissions.has(currentNeedPermissions[i])) {
            return true;
          }
        }
        return false;
      },
      canComponentsAccess({ key }: { key: string }) {
        return currentUserPermissions.has(key);
      },
    },
    {
      get(target, key) {
        if (key in target) {
          return target[key];
        }
        return () => target.canRouteAccess({ name: key });
      },
    },
  );
}
