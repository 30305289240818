import { request } from 'umi';

/** 登录 */
export async function login(body: API.LoginRequest) {
  return request<API.Response<API.LoginResponse>>(`${apiPrefix.AUTH_CENTER}/user/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  });
}
(window as any).request = request;

/** 查询当前登录的用户权限列表 */
export async function getCurrentUserPermissions(userId: number) {
  return request<API.Response<API.Permission[]>>(
    `${apiPrefix.AUTH_CENTER}/admin/permission/user/${userId}`,
    {
      method: 'GET',
    },
  );
}

/** 获取当前的用户 */
export async function currentUser(options?: Record<string, any>) {
  return request<API.Response<API.User>>(`${apiPrefix.AUTH_CENTER}/user/current`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 退出登录接口 */
export async function outLogin(options?: Record<string, any>) {
  return request<API.Response<Record<string, any>>>(`${apiPrefix.AUTH_CENTER}/user/logout`, {
    method: 'POST',
    ...(options || {}),
  });
}

/** 更新密码接口 */
export async function updatePassword(password: string) {
  return request<API.Response<Record<string, any>>>(
    `${apiPrefix.AUTH_CENTER}/user/update-password`,
    {
      method: 'PUT',
      data: { password },
    },
  );
}
