/**
 * 在生产环境 代理是无法生效的，所以这里没有生产环境的配置
 * -------------------------------
 * The agent cannot take effect in the production environment
 * so there is no configuration of the production environment
 * For details, please see
 * https://pro.ant.design/docs/deploy
 */

function createHost(prefix = '') {
  let _prefix = '';
  if (prefix) {
    _prefix = prefix + '.';
  }
  return {
    AUTH_CENTER: `https://${_prefix}admin-auth-center.happycash.ph/`,
    USER: `https://${_prefix}admin-user.happycash.ph/`,
    MESSAGE: `https://${_prefix}admin-message.happycash.ph/`,
    EVENTTRACKING: `https://${_prefix}admin-event-tracking.happycash.ph/`,
    RISK_INDICATOR: `https://${_prefix}indicator-riskmanagement.happycash.ph/`,
    LIMIT: `https://${_prefix}admin-limit.happycash.ph/`,
    ORDER: `https://${_prefix}admin-order.happycash.ph/`,
    AUDIT: `https://${_prefix}admin-audit.happycash.ph/`,
    COLLECTION: `https://${_prefix}admin-collection.happycash.ph/`,
    ORDER_RISK: `https://${_prefix}order-riskmanagement.happycash.ph/`,
    CONFIG: `https://${_prefix}admin-config.happycash.ph`,
    MARKETING: `https://${_prefix}admin-marketing.happycash.ph`,
  };
}

export const PATH = Object.keys(createHost()).reduce((previousValue, currentValue) => {
  return {
    ...previousValue,
    [currentValue]: '/' + currentValue,
  };
}, {});

export const HOST_DEV = {
  ...createHost('dev'),
};

export const HOST_ID_DEV = {
  ...createHost('id-dev'),
};

export const HOST_TEST = {
  ...createHost('test'),
};
export const HOST_SIT = {
  ...createHost('sit'),
};
export const HOST_PROD = {
  ...createHost(),
};

function createProxyConfig(hostConfig: { [k: string]: string }) {
  return Object.entries(hostConfig).reduce((previousValue, currentValue) => {
    return {
      ...previousValue,
      ['/' + currentValue[0] + '/']: {
        target: currentValue[1],
        changeOrigin: true,
        pathRewrite: { [`^/${currentValue[0]}/`]: '' },
      },
    };
  }, {});
}

export default {
  dev: createProxyConfig(HOST_DEV),
  id_dev: createProxyConfig(HOST_ID_DEV),
  test: createProxyConfig(HOST_TEST),
  sit: createProxyConfig(HOST_SIT),
  pre: createProxyConfig(HOST_PROD),
};
