// @ts-nocheck
import { RouteAccessMap } from './accessIds'
// import {app} from "@@/plugin-generate-access/accessUtil";

function generateRouteAccess(routes: any[]): string{
  // const removes = [];
  routes.forEach((route: any,index)=>{
    // if (route.app && app && route.app.trim().toLowerCase() !== app) {
    //   removes.push(index);
    // }
    if (route.routes){
      generateRouteAccess(route.routes)
    }
    if(RouteAccessMap[route.accessId]){
      route.access = route.accessId
    }
  })

  // removes.reverse().forEach(value => {
  //   routes.splice(value,1)
  // })
}


export function patchRoutes({ routes }) {
  generateRouteAccess(routes)
}
