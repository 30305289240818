/**
 * Created by cleverdou on 2021/9/18.
 */

import React from 'react';
import { localeInfo, FormattedMessage as UFormattedMessage } from '@@/plugin-locale/localeExports';

type Props = {
  id: string;
  defaultMessage?: string;
};

const firstUpperCase = ([first, ...rest]: string[]) => first?.toUpperCase() + rest.join('');

const FormattedMessage: React.FC<Props> = (props: any) => {
  const messages = props.id.split('.');
  return (
    <UFormattedMessage
      defaultMessage={
        localeInfo?.['en-US']?.messages?.[props.id] ?? firstUpperCase(messages[messages.length - 1])
      }
      {...props}
    />
  );
};
export default FormattedMessage;
