import { type InputProps, Spin } from 'antd';
// import { message } from 'antd';
import type { ModalFormProps } from '@ant-design/pro-form';
import {
  ModalForm,
  ProFormDatePicker,
  ProFormDateRangePicker,
  ProFormDigit,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormSwitch,
  ProFormTimePicker,
} from '@ant-design/pro-form';
import type { ProFormSelectProps } from '@ant-design/pro-form/lib/components/Select';
import type { ProFormDigitProps } from '@ant-design/pro-form/lib/components/Digit';
import type { ProFormRadioGroupProps } from '@ant-design/pro-form/lib/components/Radio';
import type { RangePickerProps } from 'antd/lib/date-picker';
import type { DatePickerProps } from 'antd/lib/date-picker';
import type { ProFormSwitchProps } from '@ant-design/pro-form/lib/components/Switch';
import { isFunction } from 'lodash';
import FormattedMessage from '../FormattedMessage/FormattedMessage';
import type { ProFormFieldItemProps } from '@ant-design/pro-form/es/typing';

export type ProFormTextProps = ProFormFieldItemProps<InputProps>;
export type ModalFormTextAreaProps = ProFormTextProps;
export type ModalFormPasswordProps = ProFormTextProps;
export type FeildType =
  | 'inputNumber'
  | 'textArea'
  | 'password'
  | 'radioGroup'
  | 'date'
  | 'select'
  | 'dateRange'
  | 'time'
  | 'timeRange'
  | 'switch';
export type ProFormInputNumberProps = ProFormDigitProps;
export type RenderItem = (modalFormFieldProps: Record<string, any>) => React.ReactNode | Element;
export type ModalFormFieldProps = ((
  | ProFormSelectProps
  | ProFormTextProps
  | ProFormSelectProps
  | ModalFormTextAreaProps
  | ProFormInputNumberProps
  | ProFormRadioGroupProps
  | ModalFormPasswordProps
  | ProFormFieldItemProps<RangePickerProps>
  | ProFormSwitchProps
  | ProFormFieldItemProps<DatePickerProps>
  | { renderItem?: RenderItem }
) & {
  feildType?: FeildType;
  disabled?: boolean;
})[];

type Props<T> = {
  visible?: boolean;
  loading?: boolean;
  title?: React.ReactNode;
  onCancel?: () => any;
  onFinish?: (form: T) => Promise<boolean | void> | undefined;
  fieldProps: ModalFormFieldProps;
  children?: React.ReactNode;
  readOnly?: boolean;
  isEdit?: boolean;
  hide?: boolean;
  modalProps?: any;
} & ModalFormProps<T>;
export default <T extends Record<string, any>>({
  visible,
  title,
  fieldProps,
  onCancel,
  onFinish,
  formRef,
  children,
  readOnly,
  isEdit,
  hide,
  submitter,
  modalProps,
  loading = false,
  ...resetPorps
}: Props<T>) => {
  // const ref = useRef<FormInstance<T>>();
  // useEffect(() => {
  //   if (formRef) {
  //     // eslint-disable-next-line no-param-reassign
  //     formRef.current = ref.current;
  //   }
  // }, [formRef, ref]);
  return (
    <ModalForm
      {...resetPorps}
      visible={visible}
      className={loading ? 'LoadingModalFormWrap' : ''}
      title={
        // 优先取title 没有则根据readOnly isEdit 展示对应title
        title ??
        (readOnly ? (
          <FormattedMessage id="common.read" />
        ) : isEdit ? (
          <FormattedMessage id="common.edit" />
        ) : (
          <FormattedMessage id="common.create" />
        ))
      }
      formRef={formRef}
      submitter={
        submitter === false || submitter
          ? submitter
          : {
              render(props, defaultDoms) {
                // 只读模式不显示确定按钮（防止提交表单）
                return readOnly ? defaultDoms?.[0] : defaultDoms;
              },
            }
      }
      // autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        onCancel: onCancel ? () => onCancel() : undefined,
        maskClosable: false,
        ...modalProps,
      }}
      onFinish={async (values) => {
        try {
          return (await onFinish?.(values)) ?? true;
        } catch (error) {
          console.error(error);
          return true;
        }
      }}
    >
      <Spin spinning={loading}>
        {fieldProps.map((fieldProp: any, index) => {
          // readOnly 则传递 disabled 到所有组件
          fieldProp.disabled = readOnly || fieldProp.disabled;
          if (fieldProp?.hide) {
            return <></>;
          }
          if (fieldProp.renderItem) {
            return fieldProp.renderItem(fieldProp);
          }
          if (fieldProp.feildType === 'inputNumber') {
            return (
              <ProFormDigit
                {...fieldProp}
                fieldProps={{
                  precision: 0,
                  ...(fieldProp.fieldProps || {}),
                }}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }

          if (fieldProp.feildType === 'textArea') {
            return (
              <ProFormTextArea
                {...fieldProp}
                fieldProps={{
                  autoSize: { minRows: 2 },
                  ...((fieldProp.fieldProps as any) || {}),
                }}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }
          if (fieldProp.feildType === 'password') {
            return (
              <ProFormText.Password
                {...fieldProp}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }
          if (fieldProp.feildType === 'radioGroup') {
            return (
              <ProFormRadio.Group
                {...fieldProp}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }
          if (fieldProp.feildType === 'date') {
            return (
              <ProFormDatePicker
                {...fieldProp}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }
          if (fieldProp.feildType === 'dateRange') {
            return (
              <ProFormDateRangePicker
                {...fieldProp}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }
          if (fieldProp.feildType === 'time') {
            return (
              <ProFormTimePicker
                {...fieldProp}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }
          if (fieldProp.feildType === 'timeRange') {
            return (
              <ProFormTimePicker.RangePicker
                {...fieldProp}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }
          if (fieldProp.feildType === 'switch') {
            return (
              <ProFormSwitch
                {...fieldProp}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }
          if (
            fieldProp.feildType === 'select' ||
            fieldProp.valueEnum ||
            fieldProp.options ||
            isFunction(fieldProp.request)
          ) {
            return (
              <ProFormSelect
                {...fieldProp}
                key={String(fieldProp.name) || String(fieldProp.label) || index}
              />
            );
          }
          return (
            <ProFormText
              {...fieldProp}
              key={String(fieldProp.name) || String(fieldProp.label) || index}
            />
          );
        })}
        {children}
      </Spin>
    </ModalForm>
  );
};
